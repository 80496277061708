import { Typography, Box, Paper, Accordion, AccordionSummary, AccordionDetails, Dialog, DialogTitle, DialogContent, DialogContentText, Select, MenuItem, TextField, Autocomplete, ButtonBase, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, DialogActions, FormControl, FormControlLabel, IconButton, Pagination } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import util, { isElectron } from '../../util';
import { ArrowDownward, ArrowDropDown, CheckBox, Info, SettingsBackupRestore } from '@mui/icons-material';
import { callFAB, snackCaller } from '../../App';
import IndexPage from '../index';
import Image from '../../components/Image';

function CustomTabPanel(props) {
    const { children, value, index, cs, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, width: "100%", ...cs }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function returnPrereason(contentType, reasonID) {
    let preReasons = []
    switch (contentType.toLowerCase()) {
        case "game":
            preReasons = [
                "Game Doesn't Run",
                "Game Doesn't Download",
                "Malware or Virus",
                "Underage Content",
                "Hate Speech or Racism",
                "Spam",
                "Other"
            ]
            break;

        case "user":
            preReasons = [
                "Underage User",
                "Hate Speech or Racism",
                "Spam",
                "Hacked Account",
                "Other"
            ]
            break;

        case "GameComment":
            preReasons = [
                "Off-Topic",
                "Hate Speech or Racism",
                "Spam",
                "Other"
            ]
            break;

        case "forumpost":
            preReasons = [
                "Off-Topic",
                "Hate Speech or Racism",
                "Spam",
                "Other"
            ]
            break

        case "galleryitem":
            preReasons = [
                "Underage Content",
                "Stolen Content",
                "Hate Speech or Racism",
                "Spam",
                "Other",
            ]
            break

        default:
            preReasons = [
                "Something has gone terribly wrong and you shouldn't be seeing this.",
            ]
            break;
    }

    return preReasons[reasonID]
}

const Home = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [vvalue, setVValue] = useState(0);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || "false") || false);
    if (!user) navigate("/login");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const hhandleChange = (event, newValue) => {
        setVValue(newValue);
    };

    const [dashBoardData, setDashBoardData] = useState({
        userCount: 0,
        gameCount: 0,
        fileCount: 0,
        folderCount: 0,
        cdnSize: 0,
        reportCount: 0,
        unresolvedReportCount: 0,
        visitorCount: 0,
        visitorCountLastMonth: 0,
        serverUptimeDays: 0
    })

    const [reports, setReports] = useState([])
    const [selectedReport, setSelectedReport] = useState(-1)
    const [reportCategories, setReportCategories] = useState([])
    const [resolutionDialogOpen, setResolutionDialogOpen] = useState(false)
    const [resolutionOwnerAction, setResolutionOwnerAction] = useState(-1)
    const [resolutionReporterAction, setResolutionReporterAction] = useState(3)
    const [unreviewedGames, setUnreviewedGames] = useState([])
    const [gameToReview, setGameToReview] = useState(null)
    const [denialDialogVisible, setDenialDialogVisible] = useState(false)
    const [denialReason, setDenialReason] = useState("")
    const [approvalDialogVisible, setApprovalDialogVisible] = useState(false)
    const [approvalReason, setApprovalReason] = useState("")
    const [userList, setUserList] = useState([])
    const [userListPage, setUserListPage] = useState(1)
    const [impersonationWarningDialog, setImpersonationWarningDialog] = useState(false)
    const [impersonationTarget, setImpersonationTarget] = useState(null)
    const [moderateDialogVisible, setModerateDialogVisible] = useState(false)
    const [moderateTarget, setModerateTarget] = useState(null)
    const [moderateAction, setModerateAction] = useState(-1)
    const [moderateDuration, setModerateDuration] = useState(null)
    const [moderateReason, setModerateReason] = useState("")
    const [userInformationDialogVisible, setUserInformationDialogVisible] = useState(false)
    const [userInformationTarget, setUserInformationTarget] = useState(null)
    const [userInformationDialogData, setUserInformationDialogData] = useState({})
    const [emailHovered, setEmailHovered] = useState(false);
    const [editUserDialogVisible, setEditUserDialogVisible] = useState(false)
    const [editUserTarget, setEditUserTarget] = useState(null)
    const [editUserDialogData, setEditUserDialogData] = useState({})

    useEffect(() => {
        if (!editUserTarget) return;

        util.postRequest("/api/admin/getUser", {
            target: editUserTarget
        }).then(async (res) => {
            setEditUserDialogData(res)
            console.log(res)
        })
    }, [editUserTarget])

    useEffect(() => {
        if (!userInformationTarget) return;

        util.postRequest("/api/admin/getUser", {
            target: userInformationTarget
        }).then(async (res) => {
            setUserInformationDialogData(res)
            console.log(res)
        })
    }, [userInformationTarget])

    useEffect(() => {
        if (value === 0) {
            util.postRequest("/api/admin/getDashboard").then(data => {
                setDashBoardData(data);
            })
        } else if (value === 1) {
            util.postRequest("/api/admin/getReports").then(data => {
                setReports(data);
                setSelectedReport(-1)
                let cats = []
                data.forEach(report => {
                    if (!cats.includes(report.contentType)) {
                        cats.push(report.contentType)
                    }
                })

                setReportCategories(cats)
            })
        } else if (value === 2) {
            util.getRequest("/api/games/?getall=true&unreviewed=true").then(data => {
                setUnreviewedGames(data.games)
            })
        } else if (value === 3) {
            util.postRequest("/api/admin/getUsers", {
                page: userListPage
            }).then(data => {
                console.log(data.users)
                setUserListPage(data.page)
                setUserList(data.users)
            })
        }
    }, [value]);

    if (user.rank < 4) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '90vh',
                }}
            >
                <Typography variant="h3" gutterBottom>
                    welp, this is awkward
                </Typography>

                <Typography variant="p">
                    You are trying to access a page that you are not authorized to access.
                </Typography>

                <Typography variant="p" gutterBottom>
                    You shouldn't be here. Go back. Shoo shoo.
                </Typography>

                <Button
                    variant="contained"
                    onClick={() => {
                        navigate("/");
                    }}
                    sx={{
                        mt: 4
                    }}
                >
                    Go back
                </Button>


            </Box>
        );
    }

    return (
        <Box style={{ marginTop: 20, margin: 0, maxWidth: "xs", height: "90vh" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Dashboard" />
                    <Tab label="User Reports" />
                    <Tab label="Pending Games" />
                    {
                        user.rank >= 5 &&
                        <Tab label="User Manager" />
                    }
                    {
                        user.rank >= 5 &&
                        <Tab label="Analytics" />
                    }
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <Typography variant="h3" gutterBottom>Dashboard</Typography>
                <Typography variant="p" gutterBottom>
                    Welcome to the dashboard. This is where you can see all the important stuff. Do your staff thing here. You know what to do. Right? (I hope so)
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                m: 2,
                                width: 200,
                                height: 130
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Total Users
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {dashBoardData.userCount}
                            </Typography>
                        </Paper>

                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                m: 2,
                                width: 200,
                                height: 130
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Total Games
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {dashBoardData.gameCount}
                            </Typography>
                        </Paper>

                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                m: 2,
                                width: 200,
                                height: 130
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Total Files
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {dashBoardData.fileCount}
                            </Typography>
                        </Paper>

                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                m: 2,
                                width: 200,
                                height: 130
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Total Folders
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {dashBoardData.folderCount}
                            </Typography>
                        </Paper>

                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                m: 2,
                                width: 230,
                                height: 130
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                CDN Size
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {dashBoardData.cdnSize} GB
                            </Typography>
                        </Paper>

                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                m: 2,
                                width: 200,
                                height: 150
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Total Reports<br /><Typography variant="body1">(All)</Typography>
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {dashBoardData.reportCount}
                            </Typography>
                        </Paper>

                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                m: 2,
                                width: 200,
                                height: 150
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Total Reports<br /><Typography variant="body1">(Unresolved)</Typography>
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {dashBoardData.unresolvedReportCount}
                            </Typography>
                        </Paper>

                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                m: 2,
                                width: 300,
                                height: 150
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Total Requests<br /><Typography variant="body1">(All Time)</Typography>
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {dashBoardData.visitorCount}
                            </Typography>
                        </Paper>

                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                m: 2,
                                width: 300,
                                height: 150
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Total Requests<br /><Typography variant="body1">(1 Month)</Typography>
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {dashBoardData.visitorCountLastMonth}
                            </Typography>
                        </Paper>

                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                m: 2,
                                width: 300,
                                height: 150
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Server Uptime
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                {dashBoardData.serverUptimeDays} days
                            </Typography>
                        </Paper>
                    </Box>
                </Box>
            </CustomTabPanel>

            <CustomTabPanel cs={{
                width: "100%",
                height: "85vh"
            }} value={value} index={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <Box
                        sx={{
                            height: "100%",
                            width: "15%"
                        }}
                    >
                        <Typography variant="h5" gutterBottom>
                            Reports
                        </Typography>

                        {
                            reportCategories.map((cat, index) => {
                                return (
                                    <Accordion
                                        sx={{
                                            mt: 1
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDown />}
                                        >
                                            <Typography variant="h6">
                                                {cat}
                                            </Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            {
                                                reports.map((report, index) => {
                                                    if (report.contentType === cat) {
                                                        return (
                                                            <Button
                                                                key={index}
                                                                variant="outlined"
                                                                fullWidth
                                                                onClick={() => {
                                                                    setSelectedReport(index)
                                                                }}
                                                                sx={{
                                                                    mt: 1
                                                                }}
                                                            >
                                                                {report.user.username}
                                                            </Button>
                                                        )
                                                    }
                                                })
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                    </Box>

                    <Box
                        sx={{
                            height: "100%",
                            width: "85%",
                        }}
                    >
                        {
                            selectedReport === -1 ?
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <Typography variant="h5" gutterBottom>
                                        Select a report to view details
                                    </Typography>
                                </Box>
                                :
                                <Box
                                    sx={{
                                        paddingLeft: 6,
                                        position: "relative"
                                    }}
                                >
                                    <Image
                                        src={reports[selectedReport].contentBackground}
                                        alt="Reported Content"
                                        style={{
                                            width: "100%",
                                            height: 200,
                                            borderRadius: 6,
                                            position: "absolute",
                                            left: 0,
                                            overflow: "hidden",
                                            objectFit: "cover"
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            height: 200,
                                            //backgroundImage: `url(${reports[selectedReport].contentBackground})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            borderRadius: 2,
                                            overflow: "hidden"
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                                                borderRadius: 2,
                                                backdropFilter: "blur(10px)",
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                paddingTop: 20,
                                                position: 'relative'
                                            }}
                                        >
                                            <Typography variant="h5" gutterBottom>
                                                Report Details
                                            </Typography>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    width: "100%",
                                                    justifyContent: 'space-between',
                                                    padding: 2,
                                                    paddingBottom: 0
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'center',
                                                        height: '100%',
                                                        gap: 5
                                                    }}
                                                >
                                                    <Typography variant="h6" gutterBottom>
                                                        Reported By: {reports[selectedReport].user.username}
                                                    </Typography>

                                                    <Typography variant="h6" gutterBottom>
                                                        Reported On: {new Date(reports[selectedReport].created).toLocaleString()}
                                                    </Typography>
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'center',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <Typography variant="h6" gutterBottom>
                                                        Content Type: {reports[selectedReport].contentType}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </div>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        <Typography variant="h5" gutterBottom>
                                            Report Reason
                                        </Typography>

                                        <Typography variant="p" gutterBottom>
                                            {returnPrereason(reports[selectedReport].contentType, reports[selectedReport].preReason)}
                                        </Typography>

                                        <Typography variant="h5" gutterBottom>
                                            Reported Content
                                        </Typography>

                                        <Button
                                            variant="outlined"
                                            sx={{
                                                mb: 2,
                                            }}
                                            onClick={() => {
                                                switch (reports[selectedReport].contentType) {
                                                    case "Game":
                                                        if (isElectron()) {
                                                            window.ipcRenderer.invoke("createWindow", {
                                                                url: window.location.origin + "#" + reports[selectedReport].contentURL
                                                            })
                                                        } else {
                                                            window.open(window.location.origin + "/" + reports[selectedReport].contentURL, "_blank")
                                                        }
                                                        break;
                                                }
                                            }}
                                        >
                                            View Content ({
                                                reports[selectedReport].contentType === "Game" ?
                                                    "Game" :
                                                    reports[selectedReport].contentType === "User" ?
                                                        "User" :
                                                        reports[selectedReport].contentType === "GameComment" ?
                                                            "Game Comment" :
                                                            reports[selectedReport].contentType === "ForumPost" ?
                                                                "Forum Post" :
                                                                reports[selectedReport].contentType === "GalleryItem" ?
                                                                    "Gallery Item" :
                                                                    "Unknown ???"
                                            })
                                        </Button>

                                        <Typography variant="h5" gutterBottom>
                                            Report Details
                                        </Typography>

                                        <Typography variant="p" gutterBottom>
                                            {reports[selectedReport].reason}
                                        </Typography>

                                        <Typography variant="h5" gutterBottom>
                                            Report Status
                                        </Typography>

                                        <Typography variant="p" gutterBottom>
                                            {reports[selectedReport].resolved ? "Resolved" : "Unresolved"}
                                        </Typography>

                                        <Typography variant="h5" gutterBottom>
                                            Report Resolution
                                        </Typography>

                                        <Typography variant="p" gutterBottom>
                                            {reports[selectedReport].resolved ? reports[selectedReport].resolution : "N/A"}
                                        </Typography>

                                        <Accordion
                                            sx={{
                                                mt: 2,
                                                textAlign: 'left'
                                            }}
                                            disabled={!reports[selectedReport].resolved}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDown />}
                                            >
                                                <Typography variant="h6">
                                                    {reports[selectedReport].resolved ? "Resolution Info" : "Resolution Info (Unavailable)"}
                                                </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Typography variant="h5" gutterBottom>
                                                    Report Resolution Date
                                                </Typography>

                                                <Typography variant="p" gutterBottom>
                                                    {reports[selectedReport].resolved ? new Date(reports[selectedReport].resolvedDate).toLocaleString() : "N/A"}
                                                </Typography>

                                                <Typography variant="h5" gutterBottom>
                                                    Report Resolved By
                                                </Typography>

                                                <Typography variant="p" gutterBottom>
                                                    {reports[selectedReport].resolved ? reports[selectedReport].resolvedBy : "N/A"}
                                                </Typography>

                                                <Typography variant="h5" gutterBottom>
                                                    Report Resolution Reason
                                                </Typography>

                                                <Typography variant="p" gutterBottom>
                                                    {reports[selectedReport].resolved ? reports[selectedReport].resolutionReason : "N/A"}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Button
                                            variant="outlined"
                                            sx={{
                                                mt: 2
                                            }}
                                            onClick={() => {
                                                setResolutionDialogOpen(true)
                                            }}
                                        >
                                            Resolve Report
                                        </Button>

                                        <Dialog
                                            open={resolutionDialogOpen}
                                            onClose={() => {
                                                setResolutionDialogOpen(false)
                                            }}
                                        >
                                            <DialogTitle>Resolve Report</DialogTitle>

                                            <DialogContent>
                                                <Typography
                                                    variant="h5"
                                                    gutterBottom
                                                >
                                                    Actions to take
                                                </Typography>

                                                <Typography
                                                    variant="p"
                                                    gutterBottom
                                                >
                                                    Please select an action to take on this report.<br /><br />
                                                </Typography>

                                                <Typography
                                                    variant="p"
                                                    gutterBottom
                                                >
                                                    What to do with the reported content's owner?
                                                    <br />
                                                    <span style={{ fontSize: 12 }}>(Anything else than "Ignore" will also disable the content)</span>
                                                </Typography>

                                                <Select
                                                    fullWidth
                                                    sx={{
                                                        mt: 2,
                                                        mb: 2
                                                    }}
                                                    value={resolutionOwnerAction}
                                                    onChange={(e) => {
                                                        setResolutionOwnerAction(e.target.value)
                                                    }}
                                                >
                                                    <MenuItem disabled
                                                        value={-1}
                                                    >
                                                        Select an action
                                                    </MenuItem>
                                                    <MenuItem value={0}>Warn</MenuItem>
                                                    <MenuItem value={1}>Suspend</MenuItem>
                                                    <MenuItem value={2}>Site Ban</MenuItem>
                                                    <MenuItem value={3}>Delete Content</MenuItem>
                                                    <MenuItem value={4}>Ignore</MenuItem>
                                                    <MenuItem value={5}>Hard Ban</MenuItem>
                                                </Select>

                                                {resolutionOwnerAction === 1 || resolutionOwnerAction === 2 ?
                                                    <Box
                                                        sx={{
                                                            mb: 2
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="p"
                                                            gutterBottom
                                                        >
                                                            Select Duration (in days) for the suspension/ban. (0 for permanent)
                                                        </Typography>

                                                        <TextField
                                                            fullWidth
                                                            type="number"
                                                            sx={{
                                                                mt: 2,
                                                                mb: 2
                                                            }}
                                                            placeholder="Be reasonable. Don't go overboard."
                                                        />

                                                        <Typography
                                                            variant="p"
                                                            gutterBottom
                                                        >
                                                            Write a reason for the suspension/ban. Will be shown to the user.
                                                        </Typography>

                                                        <TextField
                                                            fullWidth
                                                            sx={{
                                                                mt: 2,
                                                                mb: 2
                                                            }}
                                                            placeholder="Be fair. Be polite."
                                                        />
                                                    </Box>
                                                    : <></>
                                                }

                                                <Typography
                                                    variant="p"
                                                    gutterBottom
                                                    sx={{
                                                    }}
                                                >
                                                    What to do with the reporter?
                                                    <br />
                                                    <span style={{ fontSize: 12 }}>(Don't select anything if the report is not a spam.)</span>
                                                </Typography>

                                                <Select
                                                    fullWidth
                                                    sx={{
                                                        mt: 2,
                                                        mb: 2
                                                    }}
                                                    value={resolutionReporterAction}
                                                    onChange={(e) => {
                                                        setResolutionReporterAction(e.target.value)
                                                    }}
                                                >
                                                    <MenuItem value={0}>Warn</MenuItem>
                                                    <MenuItem value={1}>Suspend</MenuItem>
                                                    <MenuItem value={2}>Site Ban</MenuItem>
                                                    <MenuItem value={3}>Ignore</MenuItem>
                                                    <MenuItem value={4}>Hard Ban</MenuItem>
                                                </Select>

                                                {resolutionReporterAction === 1 || resolutionReporterAction === 2 ?
                                                    <Box
                                                        sx={{
                                                            mb: 2
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="p"
                                                            gutterBottom
                                                        >
                                                            Select Duration (in days) for the suspension/ban. (0 for permanent)
                                                        </Typography>

                                                        <TextField
                                                            fullWidth
                                                            type="number"
                                                            sx={{
                                                                mt: 2,
                                                                mb: 2
                                                            }}
                                                            placeholder="Be reasonable. Don't go overboard."
                                                        />

                                                        <Typography
                                                            variant="p"
                                                            gutterBottom
                                                        >
                                                            Write a reason for the suspension/ban. Will be shown to the user.
                                                        </Typography>

                                                        <TextField
                                                            fullWidth
                                                            sx={{
                                                                mt: 2,
                                                                mb: 2
                                                            }}
                                                            placeholder="Be fair. Be polite."
                                                        />
                                                    </Box>
                                                    : <></>
                                                }

                                                <Typography
                                                    variant="p"
                                                    gutterBottom
                                                >
                                                    Write a reason for the resolution. This will be logged to the audit log and will be visible to other staff members.
                                                </Typography>

                                                <TextField
                                                    fullWidth
                                                    sx={{
                                                        mt: 2,
                                                        mb: 2
                                                    }}
                                                    placeholder="Be descriptive."
                                                />

                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{
                                                        mt: 2
                                                    }}
                                                >
                                                    Resolve Report
                                                </Button>

                                            </DialogContent>
                                        </Dialog>
                                    </Box>
                                </Box>
                        }
                    </Box>
                </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: -3 }}>
                    <Tabs sx={{}} value={vvalue} onChange={hhandleChange}>
                        <Tab label="Approve | Deny Games" />
                        <Tab label="View Games" />
                    </Tabs>
                </Box>

                <CustomTabPanel value={vvalue} index={0}>
                    <Typography variant="h3" sx={{ mt: 3 }} gutterBottom>Game Review Page</Typography>
                    <Typography variant="p" gutterBottom>
                        Try the game by going to the "View Games" tab. If it works, looks as described, and doesn't contain any malicious or illegal content, approve it. If it doesn't, deny it.<br />If you deny it, please provide a reason.<br /><br /><strong>DO NOT FORGET TO SCAN THE GAME FILES WITH AN AV. THESE FILES ARE NOT CHECKED!</strong>
                    </Typography>

                    <Box
                        sx={{
                            textAlign: "left"
                        }}
                    >
                        <Typography variant="h5" sx={{ mt: 3 }} gutterBottom>Select Game</Typography>

                        <Autocomplete
                            options={unreviewedGames}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, v) => {
                                setGameToReview(v)
                            }}
                            renderInput={(params) => <TextField {...params} label="Game Name" />}
                        />

                        {!gameToReview ?
                            <Typography variant="h5" sx={{ mt: 3, textAlign: "center" }} gutterBottom>
                                Select a game to review.
                            </Typography>
                            :
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                    height: '100%',
                                }}
                            >
                                <Typography variant="h5" sx={{ mt: 3 }} gutterBottom>Game Details</Typography>

                                <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                                    Game Name: {gameToReview.name}
                                </Typography>

                                <ButtonBase
                                    onClick={() => {
                                        if (isElectron()) {
                                            window.ipcRenderer.invoke("createWindow", {
                                                url: window.location.origin + "#/profile/" + gameToReview.developer.UUID,
                                            })
                                        } else {
                                            window.open(window.location.origin + "/profile/" + gameToReview.developer.UUID, "_blank")
                                        }
                                    }}
                                >
                                    <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                                        Game Author: {gameToReview.developer.username}
                                    </Typography>
                                </ButtonBase>

                                <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                                    Game Version: {[...gameToReview.versions].pop().version} (v{[...gameToReview.versions].pop().versionID})
                                </Typography>

                                <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                                    Game Tags: {[...gameToReview.versions].pop().tags.join(", ")}
                                </Typography>

                                <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                                    Game Media:
                                </Typography>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 5
                                    }}
                                >
                                    {
                                        [...gameToReview.versions].pop().images.map((screenshot, index) => {
                                            if (screenshot?.endsWith(".mp4")) {
                                                return (
                                                    <video
                                                        key={index}
                                                        src={screenshot}
                                                        alt={`Screenshot ${index}`}
                                                        style={{
                                                            width: 200,
                                                            borderRadius: 2
                                                        }}
                                                        controls
                                                    />
                                                )
                                            }
                                            return (
                                                <Image
                                                    key={index}
                                                    src={screenshot}
                                                    alt={`Screenshot ${index}`}
                                                    style={{
                                                        width: 200,
                                                        borderRadius: 2
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </Box>

                                <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                                    Actions
                                </Typography>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 5
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            if (isElectron()) {
                                                window.ipcRenderer.invoke("createWindow", {
                                                    url: window.location.origin + "#" + `/games/${gameToReview.appID}/${[...gameToReview.versions].pop().versionID}`,
                                                })
                                            } else {
                                                window.open(window.location.origin + "#" + `/games/${gameToReview.appID}/${[...gameToReview.versions].pop().versionID}`, "_blank")
                                            }
                                        }}
                                    >
                                        View Game Page
                                    </Button>

                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            if (isElectron()) {
                                                window.ipcRenderer.invoke("createWindow", {
                                                    url: window.location.origin + "#/profile/" + gameToReview.developer.UUID,
                                                })
                                            } else {
                                                window.open(window.location.origin + "/profile/" + gameToReview.developer.UUID, "_blank")
                                            }
                                        }}
                                    >
                                        View Developer
                                    </Button>

                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setApprovalDialogVisible(true)
                                        }}
                                    >
                                        Approve Game
                                    </Button>

                                    <Dialog
                                        open={approvalDialogVisible}
                                        onClose={() => {
                                            setApprovalDialogVisible(false)
                                        }}
                                    >
                                        <DialogTitle>Approve Game</DialogTitle>

                                        <DialogContent>
                                            <Typography
                                                variant="h5"
                                                gutterBottom
                                            >
                                                Approval Reason
                                            </Typography>

                                            <Typography
                                                variant="p"
                                                gutterBottom
                                            >
                                                Please provide a reason for the approval. This will be saved for the record. Won't be shown to the developer.
                                            </Typography>

                                            <TextField
                                                value={approvalReason}
                                                onChange={(e) => {
                                                    setApprovalReason(e.target.value)
                                                }}
                                                fullWidth
                                                sx={{
                                                    mt: 2,
                                                    mb: 2
                                                }}
                                                placeholder="Be descriptive?"
                                            />

                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{
                                                    mt: 2
                                                }}
                                                onClick={() => {
                                                    util.postRequest("/api/admin/approveGame", {
                                                        appID: gameToReview.appID,
                                                        reason: denialReason
                                                    }).then(data => {
                                                        if (data.success) {
                                                            util.postRequest("/api/games/?getall=true&unreviewed=true").then(data => {
                                                                setUnreviewedGames(data.games)
                                                            })
                                                            setGameToReview(null)
                                                            setApprovalDialogVisible(false)
                                                            setApprovalReason("")
                                                        }
                                                    })
                                                }}
                                            >
                                                Approve Game
                                            </Button>

                                        </DialogContent>
                                    </Dialog>

                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setDenialDialogVisible(true)
                                        }}
                                    >
                                        Deny Game
                                    </Button>

                                    <Dialog
                                        open={denialDialogVisible}
                                        onClose={() => {
                                            setDenialDialogVisible(false)
                                        }}
                                    >
                                        <DialogTitle>Deny Game</DialogTitle>

                                        <DialogContent>
                                            <Typography
                                                variant="h5"
                                                gutterBottom
                                            >
                                                Denial Reason
                                            </Typography>

                                            <Typography
                                                variant="p"
                                                gutterBottom
                                            >
                                                Please provide a reason for the denial. This will be shown to the developer.
                                            </Typography>

                                            <TextField
                                                value={denialReason}
                                                onChange={(e) => {
                                                    setDenialReason(e.target.value)
                                                }}
                                                fullWidth
                                                sx={{
                                                    mt: 2,
                                                    mb: 2
                                                }}
                                                placeholder="Be descriptive."
                                            />

                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{
                                                    mt: 2
                                                }}
                                                onClick={() => {
                                                    util.postRequest("/api/admin/denyGame", {
                                                        appID: gameToReview.appID,
                                                        reason: denialReason
                                                    }).then(data => {
                                                        if (data.success) {
                                                            util.postRequest("/api/games/?getall=true&unreviewed=true").then(data => {
                                                                setUnreviewedGames(data.games)
                                                            })
                                                            setGameToReview(null)
                                                            setDenialDialogVisible(false)
                                                            setDenialReason("")
                                                        }
                                                    })
                                                }}
                                            >
                                                Deny Game
                                            </Button>

                                        </DialogContent>
                                    </Dialog>

                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            setGameToReview(null)
                                        }}
                                    >
                                        Go Back
                                    </Button>
                                </Box>
                            </Box>
                        }

                    </Box>
                </CustomTabPanel>


                <CustomTabPanel value={vvalue} index={1}>
                    <IndexPage
                        adminMode={true}
                    />
                </CustomTabPanel>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
                <Typography variant="h3" gutterBottom>User Manager</Typography>
                <Typography variant="p" gutterBottom>
                    This is where you can manage users. You can view, edit, and deliver smokin' hot bans and whatnot to users from here.
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        mt: 2
                    }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Avatar
                                    </TableCell>
                                    <TableCell>
                                        Username
                                    </TableCell>
                                    <TableCell>
                                        Last Seen
                                    </TableCell>
                                    <TableCell>
                                        Profile Visibility
                                    </TableCell>
                                    <TableCell>
                                        Rank
                                    </TableCell>
                                    <TableCell>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    userList.map((Duser, index) => {
                                        return (
                                            <TableRow key={index}
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor: "#454545"
                                                    },
                                                    position: "relative",
                                                    transition: "background-color 0.2s"
                                                }}
                                            >
                                                <TableCell>
                                                    <Image
                                                        src={Duser.banner || Duser.avatar || "https://cdn.vorecade.com/defaults/avatar.webp"}
                                                        alt={Duser.username}
                                                        style={{
                                                            width: 36,
                                                            height: 36,
                                                            objectFit: "cover",
                                                            borderRadius: 64,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {Duser.username}
                                                </TableCell>
                                                <TableCell>
                                                    {Duser.lastSeen ? new Date(Duser.lastSeen).toLocaleString() : "Never ????"}
                                                </TableCell>
                                                <TableCell>
                                                    {Duser.profileVisibility ? "Public" : "Private"}
                                                </TableCell>
                                                <TableCell>
                                                    {util.getUserRank(Duser.rank)}
                                                </TableCell>
                                                <TableCell>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: 2
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                if (isElectron()) {
                                                                    window.ipcRenderer.invoke("createWindow", {
                                                                        url: window.location.origin + "#/profile/" + Duser.UUID,
                                                                    })
                                                                } else {
                                                                    window.open(window.location.origin + "/profile/" + Duser.UUID, "_blank")
                                                                }
                                                            }}
                                                        >
                                                            View Profile
                                                        </Button>

                                                        {user.rank >= 5 && <Button
                                                            variant="contained"
                                                            disabled={user.rank <= Duser.rank || Duser.rank === 0 || Duser.UUID === "00000000-0000-0000-0000-000000000000" || Duser.UUID === user.UUID}
                                                            onClick={() => {
                                                                setImpersonationWarningDialog(true)
                                                                setImpersonationTarget(Duser.UUID)
                                                            }}
                                                        >
                                                            Impersonate
                                                        </Button>}


                                                        <Button
                                                            variant="contained"
                                                            disabled={user.rank <= Duser.rank || Duser.UUID === "00000000-0000-0000-0000-000000000000" || Duser.UUID === user.UUID}
                                                            onClick={() => {
                                                                setModerateDialogVisible(true)
                                                                setModerateTarget(Duser.UUID)
                                                            }}
                                                        >
                                                            Moderate
                                                        </Button>



                                                        {user.rank >= 5 && <Button
                                                            variant="contained"
                                                            disabled={(user.rank <= Duser.rank && Duser.UUID !== user.UUID) || Duser.UUID === "00000000-0000-0000-0000-000000000000"}
                                                            onClick={() => {
                                                                setUserInformationDialogVisible(true)
                                                                setUserInformationTarget(Duser.UUID)
                                                            }}
                                                        >
                                                            User Info
                                                        </Button>}

                                                        {user.rank >= 5 && <Button
                                                            variant="contained"
                                                            disabled={(user.rank <= Duser.rank && Duser.UUID !== user.UUID) || Duser.UUID === "00000000-0000-0000-0000-000000000000"}
                                                            onClick={() => {
                                                                setEditUserDialogVisible(true)
                                                                setEditUserTarget(Duser.UUID)
                                                            }}
                                                        >
                                                            Edit User
                                                        </Button>}


                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>

                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                padding: 3
                            }}
                        >
                            <Pagination
                                color="primary"
                                count={Math.ceil(dashBoardData.userCount / 50)}
                                page={userListPage}
                                onChange={(e, v) => {
                                    util.postRequest("/api/admin/getUsers", {
                                        page: v
                                    }).then(data => {
                                        setUserListPage(data.page)
                                        setUserList(data.users)
                                    })
                                }}
                            />
                        </Box>

                        <Dialog
                            open={impersonationWarningDialog}
                            onClose={() => {
                                setImpersonationWarningDialog(false)
                                setImpersonationTarget(null)
                            }}
                        >
                            <DialogTitle>Impersonation Warning</DialogTitle>

                            <DialogContent>
                                <DialogContentText variant="body1" gutterBottom>
                                    Impersonating another user is a powerful action that should only be used for authorized reasons, such as:
                                    <ul>
                                        <li>Moderation purposes</li>
                                        <li>Debugging</li>
                                        <li>Testing</li>
                                    </ul>
                                    Please confirm that you understand the implications of this action and that you are proceeding with an authorized purpose.
                                    <br />
                                    This action will log you out of your current session and log you in as the target user. You will be able to perform actions as the target user.
                                </DialogContentText>

                                <Typography variant="body2" gutterBottom>
                                    With great power comes great responsibility.
                                </Typography>
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        util.postRequest("/api/admin/impersonate", {
                                            target: impersonationTarget
                                        }).then(data => {
                                            if (data.sessionKey) {
                                                window.localStorage.setItem("token", data.sessionKey)
                                                window.location.reload()
                                            }
                                        })
                                    }}
                                >
                                    Proceed
                                </Button>

                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setImpersonationWarningDialog(false)
                                        setImpersonationTarget(null)
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>


                        <Dialog
                            open={moderateDialogVisible}
                            onClose={() => {
                                setModerateDialogVisible(false)
                                setModerateTarget(null)
                                setModerateAction(-1)
                                setModerateDuration(null)
                                setModerateReason("")
                            }}
                        >
                            <DialogTitle>User Moderation</DialogTitle>

                            <DialogContent>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        mt: 2
                                    }}
                                >
                                    Please select an action to take on {userList.find(u => u.UUID === moderateTarget)?.username}
                                </Typography>

                                <Select
                                    value={moderateAction}
                                    onChange={(e) => {
                                        setModerateAction(e.target.value)
                                    }}
                                    fullWidth
                                    sx={{
                                        mt: 2,
                                        mb: 2
                                    }}
                                >
                                    <MenuItem value={-1} disabled>Select an action</MenuItem>
                                    <MenuItem value={0}>Warn</MenuItem>
                                    <MenuItem value={1}>Suspend</MenuItem>
                                    <MenuItem value={2}>Site Ban</MenuItem>
                                    <MenuItem value={3}>Hard Ban</MenuItem>
                                    <MenuItem value={4}>Delete Account</MenuItem>
                                </Select>

                                {(moderateAction === 1 || moderateAction === 2) &&
                                    <Box>
                                        <Typography
                                            variant="p"
                                            sx={{
                                                mt: 2
                                            }}
                                        >
                                            Select Duration (in days) for the suspension/ban. (0 for permanent)
                                        </Typography>

                                        <TextField
                                            fullWidth
                                            type="number"
                                            value={moderateDuration || ""}
                                            onChange={(e) => {
                                                if (e.target.value >= 0)
                                                    setModerateDuration(e.target.value)
                                            }}
                                            sx={{
                                                mt: 2,
                                                mb: 2
                                            }}
                                            placeholder="Be reasonable. Don't go overboard."
                                        />

                                        <Typography
                                            variant="p"
                                            value={moderateReason}
                                            onChange={(e) => {
                                                setModerateReason(e.target.value)
                                            }}
                                            sx={{
                                                mt: 2
                                            }}
                                        >
                                            Write a reason for the suspension/ban. Will be shown to the user.
                                        </Typography>

                                        <TextField
                                            fullWidth
                                            sx={{
                                                mt: 2
                                            }}
                                            placeholder="Be fair. Be polite."
                                        />
                                    </Box>
                                }

                                {(moderateAction === 3 || moderateAction === 4) &&
                                    <Box>
                                        <Typography
                                            variant="p"
                                            value={moderateReason}
                                            onChange={(e) => {
                                                setModerateReason(e.target.value)
                                            }}
                                            sx={{
                                                mt: 2
                                            }}
                                        >
                                            Write a reason for the ban/deletion. Will be shown to the user.
                                        </Typography>

                                        <TextField
                                            fullWidth
                                            sx={{
                                                mt: 2,
                                                mb: 2
                                            }}
                                            placeholder="Be fair. Be polite."
                                        />

                                        <Typography
                                            variant="p"
                                            sx={{
                                                mt: 2
                                            }}
                                        >
                                            This action is irreversible. Are you sure you want to proceed?
                                        </Typography>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: 2
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    mt: 2
                                                }}
                                                onClick={() => {

                                                }}
                                            >
                                                Confirm
                                            </Button>

                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    mt: 2
                                                }}
                                                onClick={() => {
                                                    setModerateDialogVisible(false)
                                                    setModerateTarget(null)
                                                    setModerateAction(-1)
                                                    setModerateDuration(null)
                                                    setModerateReason("")
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Box>
                                }

                                {moderateAction === 0 && <Box>
                                    <Typography
                                        variant="p"
                                        sx={{
                                            mt: 2
                                        }}
                                    >
                                        Write a reason for the warning. Will be shown to the user.
                                    </Typography>

                                    <TextField
                                        fullWidth
                                        value={moderateReason}
                                        onChange={(e) => {
                                            setModerateReason(e.target.value)
                                        }}
                                        sx={{
                                            mt: 2,
                                            mb: 2
                                        }}
                                        placeholder="Be fair. Be polite."
                                    />
                                </Box>}

                                {moderateAction < 3 && <Box>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            mt: 2
                                        }}
                                    >
                                        Confirm
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            mt: 2
                                        }}
                                        onClick={() => {
                                            setModerateDialogVisible(false)
                                            setModerateTarget(null)
                                            setModerateAction(-1)
                                            setModerateDuration(null)
                                            setModerateReason("")
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Box>}
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={userInformationDialogVisible}
                            onClose={() => {
                                setUserInformationDialogVisible(false)
                                setUserInformationTarget(null)
                            }}
                            fullWidth
                        >
                            <DialogTitle>User Statistics</DialogTitle>

                            <DialogContent>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead sx={{ height: 0, display: "flex", overflow: "hidden" }}>
                                            <TableRow>
                                                <TableCell>
                                                    Statistic
                                                </TableCell>
                                                <TableCell>
                                                    Value
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <strong>PUBLIC INFORMATION</strong>
                                                </TableCell>
                                                <TableCell>

                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Username
                                                </TableCell>
                                                <TableCell>
                                                    {userInformationDialogData?.user?.username}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Rank
                                                </TableCell>
                                                <TableCell>
                                                    {util.getUserRank(userInformationDialogData?.user?.rank)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Profile Visibility
                                                </TableCell>
                                                <TableCell>
                                                    {userInformationDialogData?.user?.profileVisibility ? "Public" : "Private"}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Last Seen
                                                </TableCell>
                                                <TableCell>
                                                    {userInformationDialogData?.user?.lastSeen ? new Date(userInformationDialogData?.user?.lastSeen).toLocaleString() : "Never"} ({Math.trunc((new Date().getTime() - new Date(userInformationDialogData?.user?.lastSeen).getTime()) / 86400000)} days ago)
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Account Created
                                                </TableCell>
                                                <TableCell>
                                                    {userInformationDialogData?.user?.created ? new Date(userInformationDialogData?.user?.created).toLocaleString() : "Never"} ({Math.trunc((new Date().getTime() - new Date(userInformationDialogData?.user?.created).getTime()) / 86400000)} days ago)
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <strong>SECURITY INFORMATION</strong>
                                                </TableCell>
                                                <TableCell>

                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    2FA Enabled
                                                </TableCell>
                                                <TableCell>
                                                    {userInformationDialogData?.user?.twoFactorEnabled ? "Yes" : "No (cause it's not implemented yet)"}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <Tooltip title="Single Session Mode" arrow>
                                                    <TableCell>
                                                        SSM Enabled
                                                    </TableCell>
                                                </Tooltip>
                                                <TableCell>
                                                    {userInformationDialogData?.user?.singleSession ? "Yes" : "No"}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    Email
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        sx={{
                                                            "&:hover": {
                                                                cursor: "pointer",
                                                                backgroundColor: "#00000000"
                                                            },
                                                            backgroundColor: "#000",
                                                            color: "#fff",
                                                            width: "fit-content",
                                                            padding: 2,
                                                        }}
                                                        onMouseEnter={() => setEmailHovered(true)}
                                                        onMouseLeave={() => setEmailHovered(false)}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(userInformationDialogData?.user?.email);
                                                            snackCaller("Email copied to clipboard.");
                                                        }}
                                                    >
                                                        {emailHovered ? userInformationDialogData?.user?.email : "HOVER TO REVEAL"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <strong>CDN USAGE INFORMATION</strong>
                                                </TableCell>
                                                <TableCell>

                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    CDN Filecount
                                                </TableCell>
                                                <TableCell>
                                                    {userInformationDialogData?.cdnUsage?.fileCount} Files
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    Real CDN Usage
                                                </TableCell>
                                                <TableCell>
                                                    {userInformationDialogData?.cdnUsage?.realFileSize?.toFixed(2) || 0} Mbytes
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    Shown CDN Usage
                                                </TableCell>
                                                <TableCell>
                                                    {userInformationDialogData?.cdnUsage?.shownFileSize?.toFixed(2) || 0} Mbytes
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <strong>CONNECTION INFORMATION</strong>
                                                </TableCell>
                                                <TableCell>

                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    Used IP Addresses
                                                </TableCell>
                                                <TableCell>
                                                    {userInformationDialogData?.ips?.map(ip => {
                                                        return (
                                                            <Typography key={ip} variant="p" gutterBottom>
                                                                {ip.ip} ({ip.requests || 0} requests)<br />
                                                                Last Request: {ip.lastRequest ? new Date(ip.lastRequest).toLocaleString() : "Never"}<br />
                                                                {ip.country && new Intl.DisplayNames(["en"], { type: "region" }).of(ip.country)}, {ip.city}<br /><br />
                                                            </Typography>
                                                        )
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                </TableContainer>



                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={editUserDialogVisible}
                            onClose={() => {
                                setEditUserDialogVisible(false)
                                setEditUserTarget(null)
                            }}
                            fullWidth
                        >
                            <DialogTitle>Edit User</DialogTitle>

                            <DialogContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        gap: 5
                                    }}
                                >
                                    <TextField
                                        value={editUserDialogData?.user?.username}
                                        onChange={(e) => {
                                            setEditUserDialogData({
                                                ...editUserDialogData,
                                                user: {
                                                    ...editUserDialogData.user,
                                                    username: e.target.value
                                                }
                                            })
                                        }}
                                        label='Username'
                                        sx={{
                                            mt: 2,
                                            mb: 2
                                        }}
                                        placeholder="Username"
                                    />

                                    <TextField
                                        value={editUserDialogData?.user?.email}
                                        onChange={(e) => {
                                            setEditUserDialogData({
                                                ...editUserDialogData,
                                                user: {
                                                    ...editUserDialogData.user,
                                                    email: e.target.value
                                                }
                                            })
                                        }}
                                        label='Email'
                                        sx={{
                                            mt: 2,
                                            mb: 2
                                        }}
                                        placeholder="Email"
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        gap: 5
                                    }}
                                >
                                    <Select
                                        value={editUserDialogData?.user?.rank}
                                        onChange={(e) => {
                                            setEditUserDialogData({
                                                ...editUserDialogData,
                                                user: {
                                                    ...editUserDialogData.user,
                                                    rank: e.target.value
                                                }
                                            })
                                        }}
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            width: "45%"
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem disabled key={-1} value={-1}>Select a Rank</MenuItem>
                                        {[1, 2, 3, 4, 5, 6].map(rank => {
                                            return (
                                                <MenuItem disabled={user.rank === 6 ? false : user.rank > rank} key={rank} value={rank}>{util.getUserRank(rank)}</MenuItem>
                                            )
                                        })}
                                    </Select>

                                    <TextField
                                        value={editUserDialogData?.user?.customAllowance || ""}
                                        onChange={(e) => {
                                            if (parseInt(e.target.value) < 0) return;
                                            setEditUserDialogData({
                                                ...editUserDialogData,
                                                user: {
                                                    ...editUserDialogData.user,
                                                    customAllowance: e.target.value
                                                }
                                            })
                                        }}
                                        type='number'
                                        label='Custom Allowance (Mbytes)'
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            width: "45%"
                                        }}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        gap: 5
                                    }}
                                >
                                    <Select
                                        value={editUserDialogData?.user?.profileVisibility}
                                        onChange={(e) => {
                                            setEditUserDialogData({
                                                ...editUserDialogData,
                                                user: {
                                                    ...editUserDialogData.user,
                                                    profileVisibility: e.target.value
                                                }
                                            })
                                        }}
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            width: "45%"
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem key={"proftip"} disabled>Select Profile Visibility</MenuItem>
                                        <MenuItem key={"profpriv"} value={false}>Private</MenuItem>
                                        <MenuItem key={"profpub"} value={true}>Public</MenuItem>
                                    </Select>

                                    <Select
                                        value={editUserDialogData?.user?.dataCollectionOptOut}
                                        onChange={(e) => {
                                            setEditUserDialogData({
                                                ...editUserDialogData,
                                                user: {
                                                    ...editUserDialogData.user,
                                                    dataCollectionOptOut: e.target.value
                                                }
                                            })
                                        }}
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            width: "45%"
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem key={"datatip"} disabled>Select Data Collection State</MenuItem>
                                        <MenuItem key={"datain"} value={false}>Opted-in</MenuItem>
                                        <MenuItem key={"dataout"} value={true}>Opted-out</MenuItem>
                                    </Select>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        gap: 5
                                    }}
                                >
                                    <Select
                                        value={editUserDialogData?.user?.autoUpdateClient}
                                        onChange={(e) => {
                                            setEditUserDialogData({
                                                ...editUserDialogData,
                                                user: {
                                                    ...editUserDialogData.user,
                                                    autoUpdateClient: e.target.value
                                                }
                                            })
                                        }}
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            width: "45%"
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem key={"clautouptip"} disabled>Select Client Auto Update Behavior</MenuItem>
                                        <MenuItem key={"clautoupdont"} value={false}>Don't Auto Update Client</MenuItem>
                                        <MenuItem key={"clautoupdo"} value={true}>Auto Update Client</MenuItem>
                                    </Select>

                                    <Select
                                        value={editUserDialogData?.user?.autoUpdateGames}
                                        onChange={(e) => {
                                            setEditUserDialogData({
                                                ...editUserDialogData,
                                                user: {
                                                    ...editUserDialogData.user,
                                                    autoUpdateGames: e.target.value
                                                }
                                            })
                                        }}
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            width: "45%"
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem key={"gmautouptip"} disabled>Select Game Auto Update Behavior</MenuItem>
                                        <MenuItem key={"gmautoupdont"} value={false}>Don't Auto Update Games</MenuItem>
                                        <MenuItem key={"gmautoupdo"} value={true}>Auto Update Games</MenuItem>
                                    </Select>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        gap: 5
                                    }}
                                >
                                    <Select
                                        value={editUserDialogData?.user?.singleSession}
                                        onChange={(e) => {
                                            setEditUserDialogData({
                                                ...editUserDialogData,
                                                user: {
                                                    ...editUserDialogData.user,
                                                    singleSession: e.target.value
                                                }
                                            })
                                        }}
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            width: "45%"
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem key={"ssmtip"} disabled>Select SSM Behavior</MenuItem>
                                        <MenuItem key={"ssmoff"} value={false}>SSM OFF</MenuItem>
                                        <MenuItem key={"ssmon"} value={true}>SSM ON</MenuItem>
                                    </Select>

                                    <Select
                                        value={editUserDialogData?.user?.twoFactorEnabled}
                                        onChange={(e) => {
                                            setEditUserDialogData({
                                                ...editUserDialogData,
                                                user: {
                                                    ...editUserDialogData.user,
                                                    twoFactorEnabled: e.target.value
                                                }
                                            })
                                        }}
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            width: "45%"
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem key={"2fatip"} disabled>Select 2FA Mode</MenuItem>
                                        <MenuItem key={"2faoff"} value={false}>2FA OFF</MenuItem>
                                        <MenuItem key={"2faon"} disabled value={true}>2FA ON</MenuItem>
                                    </Select>
                                </Box>
                            </DialogContent>
                        </Dialog>
                    </TableContainer>
                </Box>

            </CustomTabPanel>
        </Box>
    );
};

export default Home;