import Button from '@mui/material/Button';
import { Component, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import util from '../util';
import { Typography, Box, Tooltip, IconButton, styled, LinearProgress, ButtonBase } from '@mui/material';
import { ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpOutlined } from '@mui/icons-material';
import { keyframes } from '@emotion/react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { red, green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import logo from "../logo192.png"
import Image from '../components/Image';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const NewProposal = (props) => util.Functionify(NewProposalC, props);

class NewProposalC extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tagname: "TAGNAME",
            username: "USERNAME",
            votes: 0,
            isLiked: this.props.data.currentVote === null ? null : props.data.currentVote,
            votedFor: this.props.data.votedFor,
            votedAgainst: this.props.data.votedAgainst,
            progress: this.props.data.votedFor / (this.props.data.votedFor + this.props.data.votedAgainst) * 100 || 0,
            progressbarColor: "#ffffff"
        }
    }

    getProgressColor = (value) => {
        const maxValue = 100;
        const minValue = 0;
        const midValue = 50;

        if (value >= midValue) {
            const greenIntensity = Math.floor(((value - midValue) / (maxValue - midValue)) * 500);
            return green[greenIntensity];
        } else {
            const redIntensity = Math.floor(((midValue - value) / (midValue - minValue)) * 500);
            return red[redIntensity];
        }
    }

    componentDidMount() {
        this.setState({
            progressbarColor: this.getProgressColor(this.props.data.votedFor / (this.props.data.votedFor + this.props.data.votedAgainst) * 100),
        })
    }

    render() {
        if (this.state.dead) return null;
        const bounceAnimation = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(1.2) rotate(${this.state.isLiked === true ? 15 : -15}deg); }
  100% { transform: scale(1.2) rotate(0deg); }
`;

        const TransitionIconButton = styled(IconButton)(({ theme, selected, bouncing }) => ({
            transition: theme.transitions.create(['color', 'transform'], {
                duration: theme.transitions.duration.shortest,
            }),
            '&:hover': {
                transform: 'scale(1.2)',
            },
            animation: bouncing ? `${bounceAnimation} 0.5s ease` : 'none',
            transform: selected ? 'scale(1.2)' : 'scale(1)',
        }));

        const handleLikeClick = () => {
            util.postRequest("/api/tags/vote", { TUUID: this.props.data.UUID, vote: true }).then((res) => {
                if (res.message.includes("Already voted")) return

                util.postRequest("/api/tags/getPropositions").then((res) => {
                    this.setState({
                        votedFor: res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedFor,
                        votedAgainst: res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedAgainst,
                        progress: res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedFor / (res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedFor + res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedAgainst) * 100,
                        progressbarColor: this.getProgressColor(res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedFor / (res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedFor + res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedAgainst) * 100),
                    })
                })
            })

            this.setState({
                isLiked: true,
                likeBouncing: true,
            });
            setTimeout(() => this.setState({ likeBouncing: false }), 500);
        };

        const handleDislikeClick = () => {
            util.postRequest("/api/tags/vote", { TUUID: this.props.data.UUID, vote: false }).then((res) => {
                if (res.message.includes("Already voted")) return

                util.postRequest("/api/tags/getPropositions").then((res) => {
                    this.setState({
                        votedFor: res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedFor,
                        votedAgainst: res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedAgainst,
                        progress: res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedFor / (res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedFor + res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedAgainst) * 100,
                        progressbarColor: this.getProgressColor(res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedFor / (res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedFor + res.tags.filter((p) => p.propositionType === "new").find((p) => p.UUID === this.props.data.UUID).votedAgainst) * 100),
                    })
                })
            })

            this.setState({ isLiked: false, dislikeBouncing: true });
            setTimeout(() => this.setState({ dislikeBouncing: false }), 500);
        };

        return (
            <Box
                sx={{
                    height: 140,
                    display: 'flex',
                }}
                style={{
                    maxWidth: 400
                }}
            >
                <Image style={{
                    borderRadius: 5,
                    width: 140,
                    height: 140,
                    objectFit: 'cover',
                    borderRadius: 8,
                    backgroundColor: "#fff"
                }} src={this.props.data.proposedBy.avatar?.url || logo} alt="Tag Proposal" />

                <Box
                    sx={{
                        ml: 2,
                        mt: 1,
                        textAlign: 'left',
                    }}

                >
                    <Typography
                        variant="body1"
                        component="h3"
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        {this.props.data.name}
                    </Typography>

                    <Typography
                        variant="body2"
                        component="h4"
                        sx={{
                            mt: 1
                        }}
                    >
                        <ButtonBase onClick={() => {
                            this.props.navigate(`/profile/${this.props.data.proposedBy.UUID}`)
                        }}>{this.props.data.proposedBy.username}</ButtonBase> proposed this tag

                        <Tooltip
                            title={new Date(new Date(this.props.data.created).getTime() + 172800000)
                                .toLocaleString(navigator.language, { hour: 'numeric', minute: 'numeric', second: 'numeric', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                        >
                            <Typography
                                variant='body2'
                                component='p'
                                sx={{
                                    mt: 1,
                                    color: 'secondary',
                                    mb: -2,
                                    cursor: 'help'
                                }}
                            >
                                Voting ends in {(((new Date(this.props.data.created).getTime() + 172800000) - Date.now()) / 3600000).toFixed()} hours
                            </Typography>
                        </Tooltip>
                    </Typography>

                    <Box
                        sx={{
                            mt: 2
                        }}
                    >
                        <TransitionIconButton bouncing={this.state.likeBouncing} selected={this.state.isLiked === true} color={this.state.isLiked === true ? 'primary' : 'default'} onClick={handleLikeClick}>
                            {this.state.isLiked === true ? <ThumbUp /> : <ThumbUpOutlined />}
                        </TransitionIconButton>

                        <TransitionIconButton bouncing={this.state.dislikeBouncing} selected={this.state.isLiked === false} color={this.state.isLiked === false ? 'primary' : 'default'} onClick={handleDislikeClick}>
                            {this.state.isLiked === false ? <ThumbDown /> : <ThumbDownOutlined />}
                        </TransitionIconButton>

                        <Typography
                            variant="body1"
                            component="h3"
                            sx={{
                                display: 'inline',
                                ml: 1,
                            }}
                        >
                            {this.state.votedFor - this.state.votedAgainst === 0 ? "" : this.state.votedFor - this.state.votedAgainst > 0 ? "+" : ""}{this.state.votedFor - this.state.votedAgainst} / {this.state.votedFor + this.state.votedAgainst} votes
                        </Typography>
                    </Box>

                    <LinearProgress
                        variant='determinate'
                        value={this.state.progress}
                        sx={{
                            bgcolor: this.state.progressbarColor,
                        }}
                    />
                </Box>
            </Box>
        )
    }
}

export default class Tags extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: 0,
            proposals: [],
            tags: [],
            merges: [],
            proposalDialogOpen: false,
            tagOptions: [],
        }
    }

    componentDidMount() {
        util.postRequest("/api/tags/getPropositions").then((res) => {
            res = res.tags

            this.setState({
                proposals: res.filter((p) => p.propositionType === "new"),
                tags: res.filter((p) => p.propositionType === "tagging"),
                merges: res.filter((p) => p.propositionType === "merge")
            })
        })
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.proposalDialogOpen}
                    onClose={() => this.setState({ proposalDialogOpen: false })}
                >
                    <DialogTitle>Propose a new tag</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the name of the tag you would like to propose. If the tag already exists, you will vote for it. If the tag is accepted, you will receive a notification.<br /><br />Voting is held for 48 hours.
                        </DialogContentText>
                        <Autocomplete
                            id="name"
                            options={this.state.tagOptions} // This should be an array of possible tags
                            freeSolo
                            onChange={(e, v) => {
                                if(v?.name) {
                                    this.setState({ proposedTag: v.name.toLowerCase() })
                                }
                            }}
                            getOptionLabel={(option) => option.name || option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    autoFocus
                                    margin="dense"
                                    label="Tag Name"
                                    type="text"
                                    style={{
                                        textTransform: "lowercase"
                                    }}
                                    fullWidth
                                    onChange={(e) => {
                                        util.postRequest("/api/tags/autocomplete", { query: e.target.value }).then((res) => {
                                            console.log(res)
                                            this.setState({ tagOptions: res })
                                        })

                                        this.setState({ proposedTag: e.target.value })
                                    }}
                                />
                            )}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ proposalDialogOpen: false })} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            this.setState({ proposalDialogOpen: false })
                            util.postRequest("/api/tags/propose", { name: this.state.proposedTag, propositionType: "new" })
                        }} color="primary">
                            Propose
                        </Button>
                    </DialogActions>

                </Dialog>

                <Typography
                    variant="h3"
                    component="h1"
                    sx={{
                        mt: 2,
                        mb: 5
                    }}
                >
                    Tag Proposals
                </Typography>

                <Typography
                    variant="body1"
                    component="h2"
                    sx={{
                        mt: 5,
                        mb: 5
                    }}
                >
                    These are the latest tags that have been proposed by the community. Please vote on them to help us determine which tags are most important to add to the site.
                </Typography>

                <Tabs value={this.state.currentTab} onChange={(event, newValue) => this.setState({ currentTab: newValue })}>
                    <Tab label="Proposals" />
                    <Tab label="Tagging" />
                    <Tab label="Merges" />
                </Tabs>

                <CustomTabPanel value={this.state.currentTab} index={0}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            position: "relative",
                            gap: 2
                        }}
                    >
                        <Button
                            sx={{
                                position: "absolute",
                                right: 0,
                                top: -50
                            }}
                            variant="outlined"
                            color="primary"
                            onClick={() => this.setState({ proposalDialogOpen: true })}
                        >
                            Propose New Tag
                        </Button>
                        {this.state.proposals.map((p) => {
                            return <NewProposal data={p} />
                        })}
                    </Box>
                </CustomTabPanel>
            </div>
        );
    }
}