import { TextField, Typography, Button, Box, Grid, Link, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthSubbar from '../components/authSubbar';
import Util from "../util"
import { snackCaller } from '../App';

const Register = () => {
    const [emailCode, setEmailCode] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [snackVisible, setSnackVisible] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [resetDialog, setResetDialog] = useState(false);

    const handleSignup = async (event) => {
        event.preventDefault();

        if (resetDialog) {
            if (!email) {
                snackCaller('Email is required.');
                return;
            }

            if (!email.includes('@')) {
                snackCaller('Invalid email format.');
                return;
            }

            if(!emailCode) {
                snackCaller('Email code is required.');
                return;
            }

            if (!password) {
                snackCaller('Password is required.');
                return;
            }

            if (!repeatPassword) {
                snackCaller('Repeat password is required.');
                return;
            }

            if (password !== repeatPassword) {
                snackCaller('Passwords do not match.');
                return;
            }   

            try {
                let response = await Util.postRequest("/api/auth/resetFinish", { emailCode, password });

                if (response.success) {
                    snackCaller("Password reset successfully. You can now login with your new password.");
                    navigate("/login");
                } else {
                    snackCaller('An error occurred during reset. Please try again later.');
                }
            } catch (error) {
                snackCaller('An error occurred during reset. Please try again later.');
            }
            return
        }

        try {
            let response = await Util.postRequest("/api/auth/resetStart", { email });
            console.log(response)

            if (response.success) {
                snackCaller("Email sent. Please check your email for the reset code.");
                setResetDialog(true);
            }
        } catch (error) {
            setError('An error occurred during login. Please try again later.');
        }
    };

    return (
        <Box component="form"
            onSubmit={handleSignup}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 68.5px)'
            }}>
            <Snackbar
                open={snackVisible}
                autoHideDuration={6000}
                onClose={() => setSnackVisible(false)}
                message={snackMessage}
            />
            <Typography variant="h3" component="h1" gutterBottom>
                Reset Password
            </Typography>
            {
                !resetDialog ?
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        type="email"
                        required
                    />
                    :
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%"
                        }}
                    >
                        <TextField
                            label="Email Code"
                            variant="outlined"
                            value={emailCode}
                            onChange={(e) => setEmailCode(e.target.value)}
                            margin="normal"
                            required
                        />

                        <TextField
                            label="New Password"
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            margin="normal"
                            required
                        />

                        <TextField
                            label="Repeat Password"
                            variant="outlined"
                            type="password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            margin="normal"
                            required
                        />
                    </div>
            }

            <Button variant="contained" color="primary" type="submit">
                Reset
            </Button>

            <Typography variant="p" component="p" sx={{ textAlign: "center", marginTop: 2, opacity: 0.6, fontSize: 12, maxWidth: "sm" }}>If the email you send is associated with an account, we'll send the password reset instructions to your email. If you haven't verified your email, you won't be able to reset its password.</Typography>

            <Typography variant="a" onClick={() => { navigate("/login") }} color={"primary"} sx={{ mt: 2, textDecoration: "underline", cursor: "pointer" }}>
                Already have an account? Login here
            </Typography>

            <Typography variant="a" onClick={() => { navigate("/register") }} color={"primary"} sx={{ mt: 2, textDecoration: "underline", cursor: "pointer" }}>
                Don't have an account? Register here
            </Typography>

            <AuthSubbar navigate={navigate} />
        </Box>
    );
};

export default Register;