import { Typography, Box, IconButton, Snackbar, LinearProgress, Switch, FormGroup, FormControlLabel, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { Component, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ArrowBack, CreateNewFolderOutlined, Settings, Upload, UploadFile } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import util from '../../util';
import { snackCaller, theme } from '../../App';

import Image from '../../components/Image';

import { ReactComponent as FolderLogo } from "../../svg/folder.svg"
import { ReactComponent as GenericLogo } from "../../svg/generic.svg"

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

class Item extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastClick: 0,
            decryptedData: null,
            selected: false
        };
    }

    render() {
        return (
            <Button
                sx={{
                    height: 210,
                    width: 140,
                    backgroundColor: this.props.selected || this.state.selected ? "#303030" : "#00000000",
                    cursor: "pointer",
                    flexDirection: "column",
                }}

                onContextMenu={(event) => {
                    event.preventDefault();
                    this.props.setContextMenu({
                        mouseX: event.clientX - 2,
                        mouseY: event.clientY - 4,
                    });

                    this.props.setSelectedItem(this.props.data);
                }}

                onDragOver={(event) => {
                    event.preventDefault();
                }}

                onDragEnter={(event) => {
                    event.preventDefault();
                    this.setState({ selected: true });
                }}

                onDragLeave={(event) => {
                    event.preventDefault();
                    this.setState({ selected: false });
                }}

                onDrop={(event) => {
                    this.props.dragStart(false)
                    if (this.props.data.type !== "folder") return;
                    event.preventDefault();
                    if (event.dataTransfer.getData("text/plain") === this.props.data._id) return console.log("Can't drop on itself");
                    console.log("item dropped on ", this.props.data.filename, event.dataTransfer.getData("text/plain"));
                    this.setState({ selected: false })

                    util.postRequest("/api/content/move", { id: event.dataTransfer.getData("text/plain"), parent: this.props.data._id }).then((res) => {
                        this.props.refreshFileReferences();
                    }).catch((err) => {

                    })
                }}

                draggable={true}

                onDragStart={(event) => {
                    event.dataTransfer.setData("text/plain", this.props.data._id);
                    event.dataTransfer.effectAllowed = "move";
                    this.props.dragStart(true)
                }}

                onDragEnd={(event) => {
                    this.props.dragStart(false)
                }}

                onClick={(ev) => {
                    if (Date.now() - this.state.lastClick < 300) {
                        this.props.handleItemClick(ev, this.props.data, true, this.state.decryptedData);
                    }
                    this.props.handleItemClick(ev, this.props.data);
                    this.setState({ lastClick: Date.now() });
                }}
            >
                <Box
                    sx={{
                        height: 100,
                        width: 100,
                        margin: "auto",
                        marginTop: 2,
                        position: "relative",
                        pointerEvents: "none",
                    }}
                >
                    {
                        this.props.data.type !== "folder" ?
                            ["png", "jpg", "jpeg", "webp", "gif"].some(v => this.props.data.mimetype.includes(v) || this.props.data.filename.endsWith(v)) ?
                                <Image
                                    src={this.props.data.url}
                                    alt="Item"
                                    setDecryptedData={(data) => this.setState({ decryptedData: data })}
                                    style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: 6, zIndex: 0.1, position: "absolute", top: 0, left: 0 }}
                                />
                                :
                                <div>
                                    <GenericLogo
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover", zIndex: 0.1, position: "absolute", top: 0, left: 0,
                                            fill: theme.palette.primary.main
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            position: "absolute",
                                            bottom: 20,
                                            left: 0,
                                            width: "100%",
                                            color: theme.palette.getContrastText(theme.palette.primary.main),
                                            padding: 1,
                                            textAlign: "center",
                                            fontFamily: "Russo One",
                                            fontWeight: 100,
                                            fontSize: 20,
                                        }}
                                    >
                                        {this.props.data.filename.split(".").length === 1 ? "???" : this.props.data.filename.split(".").pop().slice(0, 3)}
                                    </Typography>
                                </div>
                            : this.props.data.type === "folder" ?
                                <div>
                                    {this.props.data.childPreview.url && <Image
                                        src={this.props.data.childPreview.url}
                                        alt="content"
                                        style={{ height: "60%", width: "60%", objectFit: "cover", position: "absolute", top: 9, left: 32, borderRadius: 10 }}
                                    />}
                                    <FolderLogo
                                        alt="Item"
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover", position: "absolute", top: 0, left: 0, position: "absolute",
                                            fill: theme.palette.primary.main
                                        }}
                                    />
                                </div>
                                :
                                <div>
                                    <p>unknown filetype</p>
                                </div>
                    }

                </Box>

                <Typography
                    sx={{
                        textAlign: "center",
                        margin: "auto",
                        wordBreak: "break-word",
                        color: "#fff",
                        textTransform: "none",
                    }}
                >
                    {this.props.data.filename.split("$Ğ!Ğ$")[0].length > 40 ? this.props.data.filename.split("$Ğ!Ğ$")[0].substring(0, 40) + '...' : this.props.data.filename.split("$Ğ!Ğ$")[0]}
                </Typography>
            </Button>
        );
    }
}

const CDNRoot = (props) => {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || "false") || {});
    const [currentDir, setCurrentDir] = useState("/");
    const [currentParent, setCurrentParent] = useState(null);
    const [contextMenu, setContextMenu] = useState(null);
    const [fileDatas, setFileData] = useState([]);
    const [cdnAutoConvertOptOut, setCdnAutoConvertOptOut] = useState(null);

    function fileData() {
        if (fileDatas.length === 0) return [];
        const pathParts = currentDir.replace(/\$Ğ!Ğ\$.{32}/g, '').split('/').filter(part => part !== '');
        let parentId = null;

        for (const part of pathParts) {
            const parent = fileDatas.find(file => file.parent === parentId && file.filename.replace(/\$Ğ!Ğ\$.{32}/g, '') === part);
            if (parent) {
                parentId = parent._id;
            } else {
                break;
            }
        }

        return fileDatas.filter(file => file.parent === parentId);
    }

    const [newFolderDialog, setNewFolderDialog] = useState(false);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [selectedItems, setSelectedItems] = useState(null);
    const [totalSize, setTotalSize] = useState(0);

    function selectedItem() {
        if (selectedItems?.length === 1) return selectedItems[0];
        return selectedItems;
    }

    function isEncryptedFiletype(filename) {
        return ["png", "jpg", "jpeg", "webp", "gif", "mp4", "webm", "mov", "avi", "mkv", "apng", "flv", "wmv", "m4v"].some(v => filename.includes(v));
    }

    function setSelectedItem(item, set = false) {
        if (item) {
            if(set) {
                setSelectedItems([item]);
                return;
            }
            if (!selectedItems) return setSelectedItems([item]);

            if (!selectedItems.includes(item)) {
                setSelectedItems([...selectedItems, item]);
            }
        } else {
            setSelectedItems(null);
        }
    }

    function getSize() {
        let size = 0;
        for (const item of selectedItems) {
            if (item.type === "file")
                size += item.size;
        }
        return size.toFixed(2)
    }

    const [isDragging, setIsDragging] = useState(false);

    function dragStart(val) {
        setIsDragging(val);
    }

    function refreshFileReferences() {
        util.postRequest("/api/content/myFiles").then((res) => {
            setFileData(res.data)
            setUsage(res.usage)
            setAllowance(res.allowance || "∞")
            setCdnAutoConvertOptOut(res.cdnAutoConvertOptOut)
        })
    }

    async function handleItemClick(event, item, isDoubleClick = false, decryptedData = null) {
        if (isDoubleClick) {
            if (props.isSelectDialog && item.type === "file") {
                props.selectItem(item);
                props.close()
                return
            }
            setSelectedItems([item])

            if (item.type === "folder") {
                setCurrentParent(item._id);
                return setCurrentDir(currentDir + item.filename + "/");
            }

            if (item.type === "file") {
                if (decryptedData) return window.open(decryptedData, "_blank");
                if (util.isElectron()) {
                    snackCaller("Downloading File...")
                    let path = await window.ipcRenderer.invoke("downloadFile", {
                        url: item.url,
                        fileName: item.filename,
                        MD5: item.MD5
                    })
                    snackCaller("Opening File...")

                    window.ipcRenderer.invoke("openFile", { path: path })
                } else {
                    return window.open(item.url, "_blank");
                }
            }
        }

        const isShiftPressed = event.shiftKey;
        const isCtrlPressed = event.ctrlKey;

        if (isShiftPressed) {
            if (selectedItems) {
                const firstSelectedItem = selectedItems[0];
                const firstIndex = fileData().indexOf(firstSelectedItem);
                const lastIndex = fileData().indexOf(item);
                const itemsToSelect = fileData().slice(Math.min(firstIndex, lastIndex), Math.max(firstIndex, lastIndex) + 1);
                setSelectedItems(itemsToSelect);
            } else {
                setSelectedItems([item]);
            }
        } else if (isCtrlPressed) {
            if (selectedItems) {
                if (selectedItems.includes(item)) {
                    setSelectedItems(selectedItems.filter((i) => i !== item));
                } else {
                    setSelectedItems([...selectedItems, item]);
                }
            } else {
                setSelectedItems([item]);
            }
        } else {
            setSelectedItems([item]);
        }
    }

    if (!user) navigate("/login");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [timesIUsedUnnecessaryThingsBecauseOfReact, setTimesIUsedUnnecessaryThingsBecauseOfReact] = useState(0);
    const [usage, setUsage] = useState(0);
    const [allowance, setAllowance] = useState(0);

    useEffect(() => {
        refreshFileReferences();

        let esclistener = (event) => {
            if (event.key === "Escape") {
                setSelectedItems(null);
            }
        }

        let ctrlaListener = (event) => {
            if (event.key === "a" && event.ctrlKey) {
                event.preventDefault();
                return // FIXME
                setSelectedItems(fileData());

                setTimesIUsedUnnecessaryThingsBecauseOfReact(timesIUsedUnnecessaryThingsBecauseOfReact + 1);
            }
        }

        /*let deleteListener = (event) => {
            console.log(selectedItems?.length, selectedItem())
            if (event.key === "Delete" && selectedItem()) {
                setDeleteItemDialog(true);
            }
        }*/

        document.addEventListener("keydown", ctrlaListener);
        //document.addEventListener("keydown", deleteListener);
        document.addEventListener("keydown", esclistener);

        return () => {
            document.removeEventListener("keydown", ctrlaListener);
            //document.removeEventListener("keydown", deleteListener);
            document.removeEventListener("keydown", esclistener);
        }
    }, []);

    const onContextMenuClose = (clear) => {
        setContextMenu(null);
        if (clear) setSelectedItem(null);
    }

    const onDeleteDialogClose = () => {
        setDeleteItemDialog(false);
        setTimeout(() => {
            setSelectedItem(null);
        }, 300);
    }

    const [dragging, setDragging] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const handleDrag = (event) => {
        if (!event.dataTransfer.types.includes('Files')) return;
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        console.log("leave!!!")
        setDragging(false);
    };

    const [fileOverwriteAcknowledgeCallback, setFileOverwriteAcknowledgeCallback] = useState(null);

    const handleDrop = async (event) => {
        if (!event.dataTransfer.files && !event.dataTransfer?.types.includes('Files')) return;
        if (event.preventDefault) event.preventDefault();
        setDragging(false);

        // Handle the dropped files
        const files = event.dataTransfer.files;

        // Loop through the files
        const CHUNK_SIZE = 20 * 1024 * 1024; // 20MB

        // find if a file with the same name exists, if so, ask the user if they want to overwrite it

        if (files.length === 0) return;

        if (fileDatas.find(f => files[0].name === f.filename && f.type === "file" && f.parent === currentParent)) {
            let exist = fileDatas.find(f => files[0].name === f.filename && f.type === "file")
            setFileOverwriteDialogData({ fileName: exist.filename, UUID: exist.UUID });
            setFileOverwriteDialog(true);
            setFileOverwriteAcknowledgeCallback([async (is) => {
                if (!is) return;
                console.log(is)
                for (let file of files) {
                    let start = 0;
                    let fID = (Math.random()*1000000).toString()
                    while (start < file.size) {
                        const chunk = file.slice(start, start + CHUNK_SIZE);

                        const formData = new FormData();
                        formData.append('file', chunk);
                        formData.append('visible', true);
                        let parent = currentDir === "/" ? null : currentDir.split("/").filter(p => p !== "").pop();
                        parent = fileDatas.find(f => f.filename === parent && f.type === "folder")?._id || null;
                        formData.append('parent', parent);
                        formData.append("isChunked", file.size > CHUNK_SIZE);
                        formData.append("isLast", start + CHUNK_SIZE >= file.size); // Check if it's the last chunk
                        formData.append("name", file.name);
                        formData.append("vcid", fID)
                        formData.append("ID", exist._id);

                        // Start the upload
                        setUploading(true);

                        try {
                            await util.postRequest("/api/content/upload", formData, setUploadProgress);
                            console.log('Chunk upload complete');

                            refreshFileReferences();
                        } catch (err) {
                            console.error(err);

                            refreshFileReferences();
                        } finally {
                            // End the upload when the chunk upload is complete or an error occurs
                            setUploading(false);
                        }

                        start += CHUNK_SIZE;
                    }
                }
            }])

            console.log("EXIST!!")

            return;
        }

        for (let file of files) {
            let start = 0;
            while (start < file.size) {
                const chunk = file.slice(start, start + CHUNK_SIZE);

                const formData = new FormData();
                formData.append('file', chunk);
                formData.append('visible', true);
                let parent = currentDir === "/" ? null : currentDir.split("/").filter(p => p !== "").pop();
                parent = fileDatas.find(f => f.filename === parent && f.type === "folder")?._id || null;
                formData.append('parent', parent);
                formData.append("isChunked", file.size > CHUNK_SIZE);
                formData.append("isLast", start + CHUNK_SIZE >= file.size); // Check if it's the last chunk
                formData.append("name", file.name);
                let fID = (Math.random()*1000000).toString()
                formData.append("vcid", fID)

                // Start the upload
                setUploading(true);

                try {
                    await util.postRequest("/api/content/upload", formData, setUploadProgress);
                    console.log('Chunk upload complete');

                    refreshFileReferences();
                } catch (err) {
                    console.error(err);

                    refreshFileReferences();
                } finally {
                    // End the upload when the chunk upload is complete or an error occurs
                    setUploading(false);
                }

                start += CHUNK_SIZE;
            }
        }
    };

    const [renameDialog, setRenameDialog] = useState(false);
    const [renameFilename, setRenameFilename] = useState("");
    const [settingsDialog, setSettingsDialog] = useState(false);
    const [fileOverwriteDialog, setFileOverwriteDialog] = useState(false);
    const [fileOverwriteDialogData, setFileOverwriteDialogData] = useState({
        fileName: "",
        UUID: ""
    });


    return (
        <Box
            style={{ marginTop: 20, margin: 0 }}
            onDragOver={handleDrag}
            onDragEnter={handleDrag}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <Dialog
                open={fileOverwriteDialog}
                onClose={() => {
                    setFileOverwriteDialog(false)
                    setFileOverwriteDialogData(null)
                }}
            >
                <DialogTitle>Overwrite File</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Would you like to overwrite {fileOverwriteDialogData?.fileName}? Please update your file links if you do so. Permanent links will not change.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        fileOverwriteAcknowledgeCallback[0](true);
                        setFileOverwriteAcknowledgeCallback(null);
                        setFileOverwriteDialog(false);
                        setFileOverwriteDialogData(null);
                    }}>Overwrite</Button>
                    <Button onClick={() => {
                        setFileOverwriteDialog(false);
                        setFileOverwriteDialogData(null);
                    }}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={settingsDialog}
                onClose={() => setSettingsDialog(false)}
            >
                <DialogTitle>Settings</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <FormGroup>
                            <FormControlLabel control={<Switch onChange={(ev) => { setCdnAutoConvertOptOut(ev.target.checked) }} checked={cdnAutoConvertOptOut} />} label="Autoconvert Images to WebP" />
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        util.postRequest("/api/content/cdnAutoConvertOptOut", { optOut: cdnAutoConvertOptOut }).then((res) => {
                            setCdnAutoConvertOptOut(cdnAutoConvertOptOut);
                        });
                        setSettingsDialog(false)
                    }}>Save</Button>
                </DialogActions>

            </Dialog>

            <Dialog
                open={renameDialog}
                onClose={() => {
                    setRenameDialog(false)
                    setRenameFilename("")
                }}
            >
                <DialogTitle>Rename {selectedItem()?.type === "folder" ? "Folder" : "File"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Renaming a file won't change its URL</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        sx={{ marginTop: 2 }}
                        label="New Name"
                        type="text"
                        fullWidth
                        value={renameFilename}
                        onChange={(e) => setRenameFilename(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setRenameDialog(false)
                        setRenameFilename("")
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        util.postRequest("/api/content/rename", { id: selectedItem()._id, name: renameFilename }).then((res) => {
                            refreshFileReferences();
                        })
                        setRenameDialog(false)
                        setRenameFilename("")
                    }}>Rename</Button>
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    display: dragging ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    backgroundColor: dragging ? "rgba(0, 0, 0, 0.5)" : "transparent",
                    zIndex: dragging ? 100 : -1,
                    pointerEvents: "none"
                }}
            >
                <Typography
                    sx={{
                        color: "#fff",
                        fontSize: 40,
                        fontFamily: "Russo One",
                        fontWeight: 100,
                    }}
                >
                    Drop Files Here
                </Typography>
            </Box>

            <Snackbar open={uploading}>
                <Box
                    sx={{
                        width: "100%",
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        zIndex: 100
                    }}
                >
                    <LinearProgress variant="determinate" value={uploadProgress} />
                </Box>
            </Snackbar>

            <Dialog
                open={newFolderDialog}
                onClose={() => setNewFolderDialog(false)}
            >
                <DialogTitle>Create New Folder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Folder Name"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewFolderDialog(false)}>Cancel</Button>
                    <Button onClick={() => {
                        let parent = currentDir === "/" ? null : currentDir.split("/").filter(p => p !== "").pop();
                        parent = fileDatas.find(f => f.filename === parent && f.type === "folder")?._id || null;

                        util.postRequest("/api/content/createFolder", { filename: document.getElementById("name").value, parent: parent }).then((res) => {
                            refreshFileReferences();
                        })
                        setNewFolderDialog(false)
                    }}>Create</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteItemDialog}
                onClose={onDeleteDialogClose}
            >
                <DialogTitle>Delete {Array.isArray(selectedItem()) && selectedItem()?.length > 1 ? "Selected Items?" : selectedItem()?.type === "folder" ? "Folder" : "File"}</DialogTitle>
                <DialogContent>
                    {!Array.isArray(selectedItem()) &&
                        <DialogContentText>
                            Are you sure you want to delete {selectedItem()?.type} {selectedItem?.filename}
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDeleteDialogClose}>Cancel</Button>
                    <Button onClick={() => {
                        util.postRequest("/api/content/delete", { id: Array.isArray(selectedItem()) ? selectedItem().map(i => i._id) : [selectedItem()._id] }).then((res) => {
                            refreshFileReferences();
                        })
                        onDeleteDialogClose()
                    }}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Menu
                open={contextMenu !== null}
                onClose={() => onContextMenuClose(true)}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                {selectedItem()?.type === "file" && <MenuItem onClick={() => {
                    navigator.clipboard.writeText(selectedItem().url)
                    onContextMenuClose()
                    snackCaller("Copied to Clipboard")
                }}>Copy Link</MenuItem>}
                {Array.isArray(selectedItem()) === false && <MenuItem onClick={() => {
                    setRenameDialog(true);
                    setRenameFilename(selectedItem().filename.split("$Ğ!Ğ$")[0])
                    onContextMenuClose();
                }}>Rename</MenuItem>}
                {
                    Array.isArray(selectedItem()) === false && selectedItem()?.parent !== null &&
                    <MenuItem onClick={() => {
                        util.postRequest("/api/content/move", { id: selectedItem()._id, parent: "/" }).then((res) => {
                            refreshFileReferences();
                        }).catch((err) => {
                        })
                        onContextMenuClose();
                    }}>Move Up</MenuItem>}
                <MenuItem onClick={() => {
                    setDeleteItemDialog(true);
                    onContextMenuClose();
                }}>Delete</MenuItem>
            </Menu>
            <Box
                sx={{
                    height: "calc(100vh - 170px)",
                    //backgroundColor: "#151515",
                    pointerEvents: dragging ? "none" : "auto",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: 40,
                        backgroundColor: "#101010",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        onClick={() => {
                            const parts = currentDir.split("/");
                            parts.pop();
                            parts.pop();
                            let newDir = parts.join("/") + "/"
                            setCurrentDir(newDir);
                            let curParent = fileDatas.find(f => f.filename === parts.pop() && f.type === "folder")?._id || null;
                            setCurrentParent(curParent);
                        }}
                        disabled={currentDir === "/"}
                    >
                        <ArrowBack />
                    </IconButton>

                    <Typography
                        sx={{
                            color: "#fff",
                            margin: "auto",
                            marginTop: isDragging && currentDir !== "/" ? 0.3 : 1.7,
                            fontSize: isDragging && currentDir !== "/" ? 26 : 16,
                            lineHeight: isDragging && currentDir !== "/" ? "40px" : "16px",
                            transition: "200ms",
                            fontFamily: "Russo One",
                        }}
                        onDragOver={(event) => {
                            event.preventDefault()
                        }}
                        onDrop={(event) => {
                            if (currentDir === "/") return;
                            event.preventDefault();
                            let parts = currentDir.split("/");
                            parts.pop();
                            parts.pop();
                            let newDir = parts.join("/") + "/"
                            setCurrentDir(newDir);
                            let curParent = fileDatas.find(f => f.filename === parts.pop() && f.type === "folder")?._id || "/";
                            setCurrentParent(curParent);

                            util.postRequest("/api/content/move", { id: event.dataTransfer.getData("text/plain"), parent: curParent }).then((res) => {
                                refreshFileReferences();
                            }).catch((err) => { })
                        }}
                    >
                        {!isDragging || currentDir === "/" ? `/${user.username}${currentDir.replace(/\$Ğ!Ğ\$.{32}/g, '')}` : "DRAG HERE TO MOVE UP"}
                    </Typography>

                    <Tooltip title="Upload File">
                        <IconButton
                            onClick={() => {
                                // create a file input element and click it. then call handleDrop as if the user dropped the file
                                let input = document.createElement("input");
                                input.type = "file";
                                input.multiple = true;
                                input.style.display = "none";
                                input.onchange = (ev) => {
                                    handleDrop({ dataTransfer: { files: input.files } }).then(() => {
                                        document.body.removeChild(input); // remove the input element
                                    })
                                }
                                document.body.appendChild(input);
                                input.click();
                            }}
                        >
                            <UploadFile />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Create Folder">
                        <IconButton
                            onClick={() => setNewFolderDialog(true)}
                        >
                            <CreateNewFolderOutlined />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Settings">
                        <IconButton
                            onClick={() => setSettingsDialog(true)}
                        >
                            <Settings />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        height: "calc(100vh - 250px)",
                        //padding: "18px",
                        paddingTop: 0,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "left",
                            width: "100%",
                            overflowY: "auto",
                            maxHeight: "calc(100vh - 250px)",
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                            '-ms-overflow-style': 'none',  /* IE and Edge */
                            'scrollbar-width': 'none'
                        }}
                    >
                        {
                            fileData().map((file) => {
                                return (
                                    <Item data={file} refreshFileReferences={refreshFileReferences} dragStart={dragStart} handleItemClick={handleItemClick} selected={selectedItems?.includes(file)} setContextMenu={setContextMenu} setSelectedItem={setSelectedItem} />
                                )
                            })
                        }
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        overflowY: "auto",
                        height: 40,
                        backgroundColor: "#202020",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "10%",
                            height: 40,
                            backgroundColor: "#303030",
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                height: 40,
                                width: `${(usage / allowance) * 100}%`,
                                display: "flex",
                                position: "absolute",
                                backgroundColor: "#454545",
                                left: 0,
                            }}
                        />

                        <Typography
                            sx={{
                                color: "#fff",
                                margin: "auto",
                                zIndex: 1,
                            }}
                        >
                            {usage.toFixed(2)}/{allowance}MB
                        </Typography>
                    </Box>

                    <Typography
                        sx={{
                            color: "#fff",
                            marginLeft: 2
                        }}
                    >
                        {fileData().filter(f => f.type === "file").length} Files, {fileData().filter(f => f.type === "folder").length} Folders
                    </Typography>

                    {(selectedItems?.length || 0 > 0) && <Typography
                        sx={{
                            color: "#fff",
                            marginLeft: 4
                        }}
                    >
                        {selectedItems?.length || 0} Selected Item{selectedItems?.length > 1 ? "s" : ""}
                    </Typography>}

                    {(selectedItems?.length || 0 > 0) && <Typography
                        sx={{
                            color: "#fff",
                            marginLeft: 4
                        }}
                    >
                        {getSize()} MBytes
                    </Typography>}
                </Box>
            </Box>
        </Box >
    );
};

export default CDNRoot;