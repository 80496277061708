import { TextField, Typography, Button, Box, Grid, Link } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthSubbar from '../components/authSubbar';
import Util from "../util"
import App, { callLoadingLine } from '../App';


const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    console.log();
    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            await Util.postRequest("/api/auth/login", { username, password }, null, 'application/json').then((res) => {
                if (!res.user) return
                localStorage.setItem("user", JSON.stringify(res.user));
                window.dispatchEvent(new Event('storage'));
                callLoadingLine(-1, "")

                // get if there is an redirect url in the current url
                const redirect = window.location.href.split("redirect=")[1]
                if (redirect) {
                    // Decode the redirect URL component
                    const decodedRedirect = decodeURIComponent(redirect);
                    navigate(decodedRedirect.split("#")[1])
                } else {
                    navigate("/");
                }
            });

        } catch (error) {
            setError('An error occurred during login. Please try again later.');
        }
    };

    return (
        <Box component="form"
            onSubmit={handleLogin}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 68.5px)'
            }}>
            <Typography variant="h3" component="h1">
                Login
            </Typography>
            <TextField
                label="Username / Email"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
                required
                error={error && error.includes('username')}
                helperText={error && error.includes('username') ? error : ''}
            />
            <TextField
                label="Password"
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                required
                error={error && error.includes('password')}
                helperText={error && error.includes('password') ? error : ''}
            />
            <Button variant="contained" color="primary" type="submit">
                Login
            </Button>
            <Typography variant="a" onClick={() => { navigate("/register") }} color={"primary"} sx={{ mt: 2, textDecoration: "underline", cursor: "pointer" }}>
                Don't have an account? Register here
            </Typography>

            <Typography variant="a" onClick={() => { navigate("/resetPassword") }} color={"primary"} sx={{ mt: 2, textDecoration: "underline", cursor: "pointer" }}>
                Forgot password?
            </Typography>

            <AuthSubbar navigate={navigate} />
        </Box>
    );
};

export default Login;