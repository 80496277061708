import React from 'react';
import { theme } from '../App';

const Tag = ({ text = "TAG", style }) => {
    return (
        <div style={{
            padding: 5,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 5,
            color: "white",
            fontSize: 12,
            fontWeight: "bold",
            display: "inline-block",
            marginRight: 5,
            ...style
        }}>
            {text}
        </div>
    );
};

export default Tag;