import { Add, Edit } from '@mui/icons-material';
import { Typography, Box, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Button from '@mui/material/Button';
import { useState, Component, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import util from '../../util';
import { navigate } from '../../App';
import Image from '../../components/Image';

export class GamePane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            game: null,
            hover: false,
            dialogVisible: false,
            webWarning: false
        };
    }

    handleMouseEnter = () => {
        this.setState({ hover: true });
    }

    handleMouseLeave = () => {
        this.setState({ hover: false });
    }

    render() {
        if (this.props.isCreate) {
            return (
                <ButtonBase
                    style={{
                        width: 300,
                        height: 200,
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderRadius: 10,
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "relative",
                        overflow: "hidden",
                        pointerEvents: this.state.webWarning ? "none" : "auto"
                    }}
                    onClick={() => {
                        if (!util.isElectron() && !this.state.webWarning) {
                            this.setState({ webWarning: true });
                            return;
                        }

                        if (this.state.webWarning) return;

                        this.props.navigate("/createGame");
                    }}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    <Dialog
                        open={this.state.webWarning}
                        onClose={() => {
                            this.setState({ webWarning: false, hover: false });
                        }}
                        fullWidth
                    >
                        <DialogTitle>
                            Cannot Create Game
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText>
                                Unfortunately, you cannot edit/create games on the web version of Vorecade. Please use the desktop version.
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ webWarning: false, hover: false })}>Cancel</Button>

                            <Button onClick={() => {
                                this.setState({ webWarning: false })
                                navigate("/download")
                            }}>Download</Button>
                        </DialogActions>
                    </Dialog>

                    <Add
                        sx={{
                            fontSize: 80,
                            transition: "transform 0.3s ease-in-out",
                            transform: this.state.hover ? "rotate(-15deg)" : "rotate(0)"
                        }}
                    />
                    <Typography
                        style={{
                            position: "absolute",
                            bottom: 0,
                            transform: this.state.hover ? "translateY(-30%)" : "translateY(100%)",
                            transition: "transform 0.3s ease-in-out",
                            color: "#fff",
                            width: "100%",
                            textAlign: "center",
                            padding: "10px 0",
                            fontSize: 20,
                            fontFamily: "Russo One",
                        }}
                    >
                        Create New Game
                    </Typography>

                </ButtonBase>
            )
        }

        return (
            <div
                style={{
                    width: 300,
                    height: 200,
                    borderRadius: 10,
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    overflow: "hidden",
                    //backgroundImage: `url(${this.props.game.versions[0].thumbnail})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                }}
            >
                <Image
                    src={[...this.props.game.versions].pop().thumbnail}
                    style={{
                        width: 300,
                        height: 200,
                        borderRadius: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "absolute",
                        overflow: "hidden",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        objectFit: "cover"
                    }}
                />
                <Dialog
                    open={this.state.webWarning}
                    onClose={() => this.setState({ webWarning: false })}
                    fullWidth
                >
                    <DialogTitle>
                        Cannot Edit Game
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Unfortunately, you cannot edit/create games on the web version of Vorecade. Please use the desktop version.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({ webWarning: false })}>Cancel</Button>

                        <Button onClick={() => {
                            this.setState({ webWarning: false })
                            navigate("/download")
                        }}>Download</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dialogVisible}
                    onClose={() => this.setState({ dialogVisible: false })}
                    fullWidth
                >
                    <DialogTitle>
                        Edit Game
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Saving your changes will automatically push an update. If you're only updating the game page and not the game itself, please avoid changing anything in the Binary section.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({ dialogVisible: false })}>Cancel</Button>

                        <Button onClick={() => this.props.navigate(`/editGame/${this.props.game.appID}`)}>Continue</Button>
                    </DialogActions>
                </Dialog>

                <ButtonBase
                    style={{
                        width: 300,
                        height: 200,
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderRadius: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "relative",
                        overflow: "hidden",
                        //backgroundImage: `url(${this.props.game.versions[0].thumbnail})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                    onClick={() => {
                        if (!util.isElectron()) {
                            this.setState({ webWarning: true });
                            return;
                        }
                        this.setState({ dialogVisible: true });
                    }}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    <div
                        style={{
                            backdropFilter: this.state.hover ? "blur(5px)" : "",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            zIndex: 2,
                            transition: "0.5s",
                            backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0)"
                        }}
                    />

                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            height: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backdropFilter: "blur(5px)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            zIndex: 3
                        }}
                    >
                        <Typography
                            style={{
                                color: "#fff",
                                fontSize: 18,
                                fontFamily: "Russo One",
                            }}
                        >
                            {this.props.game.name}
                        </Typography>
                    </div>

                    {
                        this.props.game.underReview && <div
                            style={{
                                position: "absolute",
                                bottom: 0,
                                width: "100%",
                                height: 50,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backdropFilter: "blur(5px)",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                zIndex: 1
                            }}
                        >
                            <Typography
                                style={{
                                    color: "#ff0000",
                                    fontSize: 18,
                                    fontFamily: "Russo One",
                                    zIndex: 1
                                }}
                            >
                                Under Review
                            </Typography>
                        </div>
                    }
                    <Edit
                        sx={{
                            fontSize: 80,
                            transition: "transform 0.3s ease-in-out",
                            transform: this.state.hover ? "rotate(-8deg)" : "rotate(0)",
                            zIndex: 2
                        }}
                    />
                    <Typography
                        style={{
                            position: "absolute",
                            bottom: 0,
                            transform: this.state.hover ? "translateY(-30%)" : "translateY(100%)",
                            transition: "transform 0.3s ease-in-out",
                            color: "#fff",
                            width: "100%",
                            textAlign: "center",
                            padding: "10px 0",
                            fontSize: 20,
                            fontFamily: "Russo One",
                            zIndex: 2
                        }}
                    >
                        Edit Game
                    </Typography>

                </ButtonBase>
            </div>
        )
    }
}

const Home = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || "false") || false);
    if (!user) navigate("/login");

    const [myGames, setMyGames] = useState([]);

    useEffect(() => {
        util.getRequest("/api/games").then((res) => {
            let myGame = res.games.filter((game) => {
                return game.developer.UUID === user.UUID;
            })

            setMyGames(myGame);
        })
    }, [])

    return (
        <Box style={{ paddingTop: 20, margin: 0, maxWidth: "xs" }}>
            <div>
                <Typography variant="h4">Vorecade Game Manager</Typography>
                <Typography variant="body2">Create, Manage, Update everything about Games here!</Typography>
            </div>

            <Box
                sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
                <GamePane navigate={navigate} isCreate={true} />
                {myGames.map((game) => {
                    return (
                        <GamePane navigate={navigate} game={game} />
                    )
                })}
            </Box>
        </Box>
    );
};

export default Home;