import { Typography, Box, ButtonBase, FormControl, TextField, MenuItem, FormGroup, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { Component, useState } from 'react';
import { Form, Navigate, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Add, CheckBox } from '@mui/icons-material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MarkdownEditor from '../../components/MarkdownEditor';
import util from '../../util';
import { loadingCaller, confetti } from '../../App';
import Image from '../../components/Image';

function isURL(url) {
    try {
        new URL(url);
        return true;
    } catch (err) {
        return false;
    }
}

const steps = ['Basic Details', 'Game Details', 'Game Page'];

const filter = createFilterOptions()

class MediaPane extends Component {
    constructor(props) {
        super(props)

        this.state = {
            media: null,
            randInputID: Math.random().toString(36).substring(7),
            isVideo: false
        }
    }

    handleSelectClick = () => {
        document.getElementById(this.state.randInputID).click();
    }

    render() {
        return (
            <ButtonBase
                style={{
                    width: 250,
                    height: 150,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    borderRadius: 10,
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "relative",
                    overflow: "hidden"
                }}

                onClick={async () => {
                    let excludeVideos = this.props.isThumbnail || this.props.isIcon || this.props.that.media?.filter(m => m.isVideo).length >= 3 ? [] : ["mp4", "mkv", "webm"]
                    let file = await window.ipcRenderer.invoke("selectFile", {
                        extensions: ["png", "jpg", "jpeg", "jfif", "webp"].concat(excludeVideos),
                        singleFile: true
                    })

                    if (!file) return

                    let isVideo = file.filePath.endsWith(".mp4") || file.filePath.endsWith(".mkv") || file.filePath.endsWith(".webm")
                    let ext = file.filePath.split(".").pop()

                    let mimeType;
                    if (isVideo) {
                        switch (ext) {
                            case 'mp4':
                                mimeType = 'video/mp4';
                                break;
                            case 'mkv':
                                mimeType = 'video/x-matroska';
                                break;
                            case 'webm':
                                mimeType = 'video/webm';
                                break;
                            default:
                                mimeType = 'application/octet-stream'; // fallback MIME type
                        }
                    } else {
                        mimeType = 'image/png';
                    }

                    file.file = `data:${mimeType};base64,` + file.file
                    file.selectorID = this.state.randInputID
                    file.thumbnail = this.props.isThumbnail
                    file.icon = this.props.isIcon
                    file.isVideo = isVideo

                    this.setState({
                        media: file.file,
                        isVideo: isVideo
                    })

                    this.props.that.setState({
                        media: this.props.that.state.media.filter((media) => media.selectorID !== this.state.randInputID).concat(file),
                        isVideo: isVideo,
                        thumbnail: this.props.isThumbnail,
                        icon: this.props.isIcon,
                        fileName: file.filePath,
                    })
                }}
            >
                <input
                    type="file"
                    accept={this.props.isThumbnail || this.props.isIcon ? "image/*" : "image/*, video/*"}
                    id={this.state.randInputID}
                    style={{
                        display: "none"
                    }}
                    onChange={(ev) => {
                        if (ev.target.files && ev.target.files[0]) {
                            const file = ev.target.files[0];
                            const blobURL = URL.createObjectURL(file);
                            const isVideo = file.type.startsWith('video/');
                            this.setState({ media: blobURL, isVideo: isVideo });

                            let setData = {
                                media: file,
                                isVideo: isVideo,
                                thumbnail: this.props.isThumbnail,
                                icon: this.props.isIcon,
                            }

                            this.props.that.setState({ media: [...this.props.that.state.media, setData] }, () => {
                                this.props.that.setState({
                                    nextButton: this.props.that.checkValidity()
                                })
                            })
                        }
                    }}
                />

                {!this.state.media ?
                    <>
                        <Add
                            sx={{
                                fontSize: 70
                            }}
                        />

                        <Typography
                            style={{
                                color: "#fff",
                                fontSize: 25
                            }}
                        >
                            Add {this.props.isIcon ? "Icon" : this.props.isThumbnail ? "Thumbnail" : "Media"}<br />
                            {this.props.isThumbnail ? "73:45" : "16:9"}
                        </Typography>
                    </>
                    :
                    this.state.isVideo ? (
                        <video
                            src={this.state.media}
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover"
                            }}
                            autoPlay={true}
                            controls={false}
                            muted
                            loop
                        />
                    ) : (
                        <img
                            src={this.state.media}
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover"
                            }}
                        />
                    )
                }
            </ButtonBase>
        )
    }
}

export default class createGame extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeStep: 0,
            skipped: new Set(),
            nextButton: true,
            checkboxes: {
                authorized: false,
                terms: false,
                malware: false,
            },
            game: {
                name: "",
                platform: "",
                version: "",
                shortDescription: "",
                description: "",
                executableName: "",
                binary: null,
                preCompressSize: 0,
                estimatedCompressSize: 0,
                entropy: 100,
                allowAnonymous: true,
                supportPage: "",
                discordInvite: "",
            },
            tagOptions: [],
            tags: [],
            shouldCheck: false,
            media: [],
            interval: null,
            ffmpegReady: false,
            goBack: false,
            exitDialog: false
        }
    }

    componentDidMount() {
        window.onbeforeunload = (e) => {
            e.preventDefault();
            e.returnValue = '';
        };

        (async () => {
            let isSucceeded = await window.ipcRenderer.invoke("initializeFFMPEG", {})

            console.log(`FFMPEG initialization: ${isSucceeded ? "successful" : "error"}`)

            if (!isSucceeded) {
                alert("Failed to initialize FFMPEG, please try again later")

                this.setState({
                    goBack: true
                })
                return
            }

            this.setState({
                ffmpegReady: isSucceeded
            })
        })()
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.activeStep !== nextState.activeStep) {
            this.setState({ shouldCheck: true })
        }

        return true;
    }

    componentDidUpdate() {
        if (this.state.shouldCheck) {
            this.setState({ nextButton: this.checkValidity(), shouldCheck: false })
        }
    }

    checkValidity() {
        console.log("CHECKING VALIDITIY")
        switch (this.state.activeStep) {
            case 0:
                /*console.log(`
                Authorized: ${this.state.checkboxes.authorized}
                Terms: ${this.state.checkboxes.terms}
                Malware: ${this.state.checkboxes.malware}
                Allow Anonymous: ${this.state.game.allowAnonymous}
                Name: ${this.state.game.name}
                Platform: ${this.state.game.platform}
                Short Description: ${this.state.game.shortDescription.length}
                `)*/
                return !(this.state.checkboxes.authorized === true && this.state.checkboxes.terms === true && this.state.checkboxes.malware === true && this.state.game.name.length >= 2 && this.state.game.platform !== "" && this.state.game.shortDescription.length > 8)
            case 1:
                /*console.log(`
                Executable Name: ${this.state.game.executableName}
                Version: ${this.state.game.version}
                Tags: ${this.state.tags.length}
                Support Page: ${this.state.game.supportPage}
                `)*/

                if (this.state.game.supportPage && !isURL(this.state.game.supportPage)) {
                    return false
                }

                if (this.state.game.discordInvite && !isURL(this.state.game.discordInvite)) {
                    return false
                }

                if (this.state.game.discordInvite && !this.state.game.discordInvite.startsWith("https://discord.gg/")) {
                    return false
                }

                return !(this.state.game.executableName !== "" && this.state.game.version !== "" && this.state.tags.length > 2)
            case 2:
                let thumbExists = this.state.media.filter((media) => media.thumbnail).length > 0;
                let mediaExists = this.state.media.filter((media) => !media.thumbnail).length > 2;
                let descExists = this.state.game.description.length > 30;
                /*console.log(`
                Thumbnail: ${thumbExists}
                Media: ${mediaExists}
                Description: ${descExists}
                `)*/

                return !(thumbExists && mediaExists && descExists)
            default:
                return false;
        }
    }

    render() {
        const state = this.state
        const isStepOptional = (step) => {
            return false
        };

        const handleNext = () => {
            this.setState({ activeStep: this.activeStep + 1 });
        };

        const handleBack = () => {
            this.setState({ activeStep: this.activeStep - 1 });
        };


        const handleReset = () => {
            this.setState({ activeStep: 0 });
        };

        return (
            <Box sx={{ width: '100%', marginTop: 3, height: "100%" }}>
                {this.state.goBack && <Navigate to="/contentManager" />}
                <Stepper activeStep={this.state.activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {this.state.activeStep === steps.length ? (
                    <div>
                        <Typography sx={{ mt: 4, mb: 1, fontSize: 50 }}>
                            All Done!
                        </Typography>

                        <Typography sx={{ mt: 2, mb: 1, fontSize: 20 }}>
                            Your game has been successfully uploaded!
                        </Typography>

                        <Typography sx={{ mt: 2, mb: 1, fontSize: 16 }}>
                            You'll be able to see your game in your content manager.
                        </Typography>

                        {this.state.go && <Navigate to="/contentManager" />}

                        <Button
                            variant="contained"
                            onClick={() => {
                                this.setState({ go: true })
                            }}
                            sx={{
                                mt: 2,
                                mb: 5
                            }}
                        >
                            Go to Content Manager
                        </Button>
                    </div>
                ) : (
                    <Box
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            maxWidth: "xs",
                            width: "100%",

                        }}
                    >
                        <Box
                            sx={{
                                marginTop: 5,
                                position: "relative",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                flexDirection: "column",
                            }}
                        >
                            {this.state.activeStep === 0 && <Box>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={this.state.checkboxes.terms} onChange={(ev) => {
                                        this.setState({
                                            checkboxes: {
                                                authorized: this.state.checkboxes.authorized,
                                                terms: ev.target.checked,
                                                malware: this.state.checkboxes.malware
                                            }
                                        }, () => {
                                            this.setState({
                                                nextButton: this.checkValidity()
                                            })
                                        });
                                    }} />} label="I have read and agree to the terms of service" />

                                    <FormControlLabel control={<Checkbox checked={this.state.checkboxes.authorized} onChange={(ev) => {
                                        this.setState({
                                            checkboxes: {
                                                authorized: ev.target.checked,
                                                terms: this.state.checkboxes.terms,
                                                malware: this.state.checkboxes.malware
                                            }
                                        }, () => {
                                            this.setState({
                                                nextButton: this.checkValidity()
                                            })
                                        });
                                    }} />} label="I am authorized to upload this game" />

                                    <FormControlLabel control={<Checkbox checked={this.state.checkboxes.malware} onChange={(ev) => {
                                        this.setState({
                                            checkboxes: {
                                                authorized: this.state.checkboxes.authorized,
                                                terms: this.state.checkboxes.terms,
                                                malware: ev.target.checked
                                            }
                                        }, () => {
                                            this.setState({
                                                nextButton: this.checkValidity()
                                            })
                                        });
                                    }} />} label="I am not uploading malware" />

                                    {false && <FormControlLabel control={<Checkbox checked={this.state.game.allowAnonymous} onChange={(ev) => {
                                        this.setState({
                                            checkboxes: {
                                                authorized: this.state.checkboxes.authorized,
                                                terms: this.state.checkboxes.terms,
                                                malware: this.state.checkboxes.malware,
                                            },
                                            game: {
                                                ...this.state.game,
                                                allowAnonymous: ev.target.checked
                                            }
                                        }, () => {
                                            this.setState({
                                                nextButton: this.checkValidity()
                                            })
                                        });
                                    }} />} label="Allow Anonymous Vorecade Users" />}
                                </FormGroup>
                                <FormControl
                                    fullWidth
                                    sx={{
                                        mt: 1,
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        gap: 3
                                    }}
                                >
                                    <TextField
                                        label="Game Name"
                                        variant="outlined"
                                        sx={{
                                            width: 250,
                                        }}
                                        fullWidth
                                        required
                                        onChange={(ev) => {
                                            this.setState({
                                                game: {
                                                    ...this.state.game,
                                                    name: ev.target.value,
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            });
                                        }}
                                        value={this.state.game.name}
                                    />

                                    <TextField
                                        label="Game Platform"
                                        variant="outlined"
                                        sx={{
                                            width: 250,
                                            textAlign: "left"
                                        }}
                                        fullWidth
                                        required
                                        select
                                        onChange={(ev) => {
                                            this.setState({
                                                game: {
                                                    ...this.state.game,
                                                    platform: ev.target.value
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            })


                                        }}
                                        value={this.state.game.platform}
                                    >
                                        <MenuItem value="win">Windows</MenuItem>
                                        <MenuItem value="web">Web</MenuItem>
                                    </TextField>

                                    <TextField
                                        label="Short Description"
                                        variant="outlined"
                                        multiline
                                        minRows={3}
                                        sx={{
                                            width: 250,
                                        }}
                                        fullWidth
                                        required
                                        onChange={(ev) => {
                                            this.setState({
                                                game: {
                                                    ...this.state.game,
                                                    shortDescription: ev.target.value
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            })
                                        }}
                                        value={this.state.game.shortDescription}
                                        inputProps={{
                                            maxLength: 225
                                        }}
                                        helperText={`${this.state.game.shortDescription.length}/225`}
                                    />
                                </FormControl>
                            </Box>}
                            {this.state.activeStep === 1 && <Box>
                                <FormControl
                                    fullWidth
                                    sx={{
                                        mt: 1,
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        gap: 3,
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 2
                                        }}
                                    >
                                        <TextField
                                            label="Executable Name"
                                            variant="outlined"
                                            sx={{
                                                width: 250,
                                            }}
                                            fullWidth
                                            required
                                            disabled={this.state.game.binary === null}
                                            onChange={(ev) => {
                                                this.setState({
                                                    game: {
                                                        ...this.state.game,
                                                        executableName: ev.target.value,
                                                    }
                                                }, () => {
                                                    this.setState({
                                                        nextButton: this.checkValidity()
                                                    })
                                                })
                                            }}
                                            value={this.state.game.executableName}
                                        />

                                        <Button
                                            variant="contained"

                                            sx={{
                                                width: 250,
                                            }}

                                            onClick={async () => {
                                                let folder = await window.ipcRenderer.invoke("selectFolder")
                                                if (!folder) return
                                                let folderData = await window.ipcRenderer.invoke("handleGameFolder", { folder: folder })
                                                if (folderData.isError) {
                                                    alert("Failed to read game folder, please try again.\n\nMake sure the folder contains the game executable and the game data.\n(Searching for any file ending with .exe or .html)")
                                                    return
                                                }
                                                loadingCaller(true, "Calculating Entropy...")
                                                let entropy = await window.ipcRenderer.invoke("calculateFolderEntropy", { folder: folder })
                                                loadingCaller(false)

                                                this.setState({
                                                    game: {
                                                        ...this.state.game,
                                                        executableName: folderData.autoDetectedEXERelativePath,
                                                        preCompressSize: (folderData.totalSize / 1024 / 1024).toFixed(0),
                                                        estimatedCompressSize: ((folderData.totalSize / 1024 / 1024) * entropy / 100).toFixed(0),
                                                        entropy: entropy,
                                                        binary: folder
                                                    },
                                                })
                                            }}
                                        >
                                            Select Game Directory
                                        </Button>

                                        <Typography
                                            sx={{
                                                textAlign: "left",
                                                fontSize: 12
                                            }}
                                        >
                                            Please correct the executable name if it is incorrect
                                        </Typography>

                                        <Typography
                                            sx={{
                                                textAlign: "left"
                                            }}
                                        >
                                            Game Size (Pre-compress): {this.state.game.preCompressSize} MB<br />
                                            Game Size (Estimated Compression): {this.state.game.estimatedCompressSize} MB
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            flexDirection: "column"
                                        }}
                                    >
                                        <TextField
                                            label="Current Game Version"
                                            variant="outlined"
                                            sx={{
                                                width: 250,
                                            }}
                                            fullWidth
                                            required
                                            onChange={(ev) => {
                                                this.setState({
                                                    game: {
                                                        ...this.state.game,
                                                        version: ev.target.value,
                                                    }
                                                }, () => {
                                                    this.setState({
                                                        nextButton: this.checkValidity()
                                                    })
                                                })
                                            }}
                                            value={this.state.game.version}
                                        />

                                        <Typography
                                            sx={{
                                                textAlign: "left",
                                                fontSize: 12,
                                                maxWidth: 230,
                                                mt: 1
                                            }}
                                        >
                                            This is the version name of the game that will be displayed on the game page.
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flex: 1,
                                        }}
                                    >
                                        <Autocomplete
                                            multiple
                                            id="name"
                                            options={this.state.tagOptions} // This should be an array of possible tags
                                            freeSolo
                                            onChange={(e, v) => {
                                                if (!v) return
                                                let val

                                                val = v.map((tag) => {
                                                    if (tag.name) {
                                                        return tag.name
                                                    } else {
                                                        return tag
                                                    }
                                                })

                                                this.setState({ tags: val }, () => {
                                                    this.setState({
                                                        nextButton: this.checkValidity()
                                                    })
                                                })
                                            }}
                                            sx={{
                                                width: 450
                                            }}
                                            getOptionLabel={(option) => option.name || option}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.name);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        name: inputValue,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    autoFocus
                                                    label="Tags"
                                                    type="text"
                                                    style={{
                                                    }}
                                                    fullWidth
                                                    onChange={(e) => {
                                                        util.postRequest("/api/tags/autocomplete", { query: e.target.value }).then((res) => {
                                                            console.log(res)
                                                            this.setState({ tagOptions: res })
                                                        })
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>

                                    <TextField
                                        label="Support Page"
                                        error={this.state.game.supportPage && !isURL(this.state.game.supportPage)}
                                        variant="outlined"
                                        sx={{
                                            width: 250,
                                        }}
                                        fullWidth
                                        onChange={(ev) => {
                                            this.setState({
                                                game: {
                                                    ...this.state.game,
                                                    supportPage: ev.target.value
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            })
                                        }}
                                        value={this.state.game.supportPage}
                                        inputProps={{
                                            maxLength: 225
                                        }}
                                    />

                                    <TextField
                                        label="Discord Invite"
                                        error={this.state.game.discordInvite && !isURL(this.state.game.discordInvite) || this.state.game.discordInvite && !this.state.game.discordInvite.startsWith("https://discord.gg/")}
                                        variant="outlined"
                                        sx={{
                                            width: 250,
                                        }}
                                        fullWidth
                                        onChange={(ev) => {
                                            this.setState({
                                                game: {
                                                    ...this.state.game,
                                                    discordInvite: ev.target.value
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            })
                                        }}
                                        value={this.state.game.discordInvite}
                                        inputProps={{
                                            maxLength: 225
                                        }}
                                    />
                                </FormControl>
                            </Box>}
                            <Box
                                sx={{
                                    display: this.state.activeStep === 2 ? "block" : "none",
                                }}
                            >
                                <Typography
                                    sx={{
                                        mt: 2,
                                        textAlign: "center"
                                    }}
                                    variant='h5'
                                    component="h2"
                                >
                                    Game Images / Videos
                                </Typography>

                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <MediaPane
                                        that={this}
                                        isThumbnail
                                    />

                                    {false && <MediaPane
                                        that={this}
                                        isIcon
                                    />}
                                </Box>
                                <Tooltip
                                    title="You can only upload up to 3 videos, you must at least upload 3 images or videos combined."
                                >
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            textAlign: "left",
                                            width: "auto"
                                        }}
                                        variant='h5'
                                        component="h4"
                                    >
                                        Game Screenshots / Videos
                                        (Minimum 3 Items)
                                    </Typography>
                                </Tooltip>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 2,
                                        width: "90vw",
                                        maxWidth: "1400px",
                                        overflowX: "auto",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: 2,
                                            position: "relative",
                                        }}
                                    >
                                        <MediaPane
                                            that={this}
                                        />
                                        <MediaPane
                                            that={this}
                                        />
                                        <MediaPane
                                            that={this}
                                        />
                                        <MediaPane
                                            that={this}
                                        />
                                        <MediaPane
                                            that={this}
                                        />
                                        <MediaPane
                                            that={this}
                                        />
                                        <MediaPane
                                            that={this}
                                        />
                                        <MediaPane
                                            that={this}
                                        />
                                        <MediaPane
                                            that={this}
                                        />
                                        <MediaPane
                                            that={this}
                                        />
                                    </Box>
                                </Box>

                                <Typography
                                    sx={{
                                        mt: 2,
                                        textAlign: "left"
                                    }}
                                    variant='h5'
                                    component="h2"
                                >
                                    Game Description
                                </Typography>
                                <MarkdownEditor
                                    value={this.state.game.description}
                                    onChange={(ev) => {
                                        this.setState({
                                            game: {
                                                ...this.state.game,
                                                description: ev
                                            }
                                        }, () => {
                                            this.setState({
                                                nextButton: this.checkValidity()
                                            })
                                        })
                                    }}
                                />
                            </Box>
                        </Box>
                        <Dialog
                            open={this.state.exitDialog}
                            onClose={() => this.setState({ exitDialog: false })}
                        >
                            <DialogTitle>
                                Are you sure you want to exit?
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    You will lose all progress if you exit now.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ exitDialog: false })}>
                                    Cancel
                                </Button>
                                <Button onClick={() => this.setState({ goBack: true })}>
                                    Exit
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, bottom: "2rem", width: "100%", justifyContent: "space-between" }}>
                            <Button
                                color="inherit"
                                onClick={() => {
                                    if (this.state.activeStep === 0) {
                                        this.setState({ exitDialog: true })
                                        return
                                    }
                                    this.setState({ activeStep: this.state.activeStep - 1 }, () => {
                                        this.setState({ nextButton: this.checkValidity() })
                                    })
                                }}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />

                            <Button disabled={this.state.nextButton} onClick={async () => {
                                this.setState({ activeStep: this.state.activeStep + 1 }, () => {
                                    this.setState({ nextButton: this.checkValidity() })
                                })

                                if (this.state.activeStep === steps.length - 1) {
                                    loadingCaller(true, "Finishing up... Might take a while...")
                                    await window.ipcRenderer.invoke("gameSubmit", {
                                        game: this.state.game,
                                        tags: this.state.tags,
                                        media: this.state.media
                                    })

                                    loadingCaller(false)

                                    confetti.addConfetti({
                                        confettiColors: [
                                            '#ff0a54', '#ff477e', '#ff7096', '#ff85a1', '#fbb1bd', '#f9bec7',
                                        ],
                                    })
                                }
                            }}>
                                {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }
}