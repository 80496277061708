import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import util from '../util';
import { Box, ButtonBase, Typography } from '@mui/material';
import { ArrowBack, ArrowBackIos, ArrowForward } from '@mui/icons-material';
import Tag from '../components/Tag';
import Image from '../components/Image';
import { GameCard } from '.';

const SpotlightCarouselGame = ({ game, navigate, reset }) => {
    if (!game) return null;
    let gameData = game.version;

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                borderRadius: 2,
                overflow: "hidden",
            }}
        >
            <div
                style={{
                    position: "absolute",
                    zIndex: 2,
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    wrap: "wrap",
                    backdropFilter: "blur(10px)",
                    alignSelf: "center",
                    top: 0,

                }}
            >
                {gameData.tags.slice(0, 5).map((tag) => {
                    return (
                        <Button variant="contained" color="primary" sx={{ marginRight: 1, padding: 0, paddingRight: 1, paddingLeft: 1 }}
                            onClick={() => {
                                reset();
                                navigate("/tags/" + encodeURIComponent(tag))
                            }}
                        >{tag}</Button>
                    )
                })}
            </div>

            <Box
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    cursor: "pointer",
                    borderRadius: "2rem",
                }}
                onClick={() => {
                    navigate(`/games/${game.appID}/${game.version.version}`)
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        textAlign: "left",
                        marginTop: "55%",
                        color: "white",
                        fontFamily: "Russo One",
                        marginLeft: 3,
                        marginBottom: 1,
                        fontSize: "1.7rem",
                    }}
                >
                    {game.name}
                </Typography>

                <Typography
                    variant="p"
                    sx={{
                        textAlign: "left",
                        fontFamily: "Russo One",
                        marginLeft: 3,
                        marginTop: 0,
                        wordBreak: "break-word",
                        width: "calc(100% - 3rem)",
                        fontSize: "0.9rem",
                        color: "#bec7d1"
                    }}
                >
                    {gameData.shortDescription}
                </Typography>
            </Box>

            <Image
                src={gameData.thumbnail}
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    zIndex: 0,
                    borderRadius: "2rem",
                    aspectRatio: "73 / 45"
                }}
            />
        </Box>
    )
}

/*const GameCard = ({ game, type, navigate }) => {
    console.log(game)
    let version = game.version;
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            style={{
                width: 270,
                height: 240,
                margin: 10,
                borderRadius: 10,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                justifyContent: "space-between",
                position: "relative",
                cursor: "pointer",
                flexShrink: 0
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => navigate(`/games/${game.appID}/${version.versionID}`)}
        >
            <div style={{ width: "100%", height: 190, display: "flex", borderRadius: 10, overflow: 'hidden', position: 'relative' }}>
                <Image src={version.thumbnail} style={{
                    width: "100%", height: 190, objectFit: "cover",
                    filter: isHovered ? "blur(5px) brightness(0.7)" : "none",
                    transition: "0.2s", position: "absolute", zIndex: 0,
                }} />
            </div>

            <div
                style={{
                    zIndex: 1,
                    position: "absolute",
                    bottom: 60, left: 10,
                }}
            >
                <div
                    style={{
                        height: isHovered ? 30 : 0,
                        overflow: "hidden",
                        transition: "0.5s",
                    }}
                >
                    {version.tags.slice(0, 3).map((tag, i) => {
                        return <Tag key={i} text={tag} style={{ marginRight: 5 }} />
                    })}
                </div>
            </div>

            <div
                style={{
                    zIndex: 1,
                    position: "absolute",
                    top: 10,
                    width: "100%"
                }}
            >
                <div
                    style={{
                        backdropFilter: "blur(5px)",
                        width: "100%",
                        display: game.underReview ? "block" : "none",
                    }}
                >
                    <Typography variant="p" style={{ color: "red", fontSize: 16, textWrap: "wrap", margin: 0, lineHeight: 1, fontWeight: "bold" }}>GAME UNDER REVIEW<br />UNLISTED</Typography>
                </div>
            </div>

            <Typography variant="p" style={{ color: "white", zIndex: 1, textAlign: "left", fontSize: 16, position: "absolute", bottom: 0, left: 10, width: 260, height: 45, textWrap: "wrap" }}>{game.name}</Typography>
        </div>
    )
}*/

const Home = () => {
    const navigate = useNavigate();
    const [games, setGames] = useState([]);
    const [carouselIndex, setCarouselIndex] = useState(0);

    useState(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        util.postRequest("/api/tags/getTag", {
            filter: "game",
            query: decodeURIComponent(window.location.hash.split("/").pop())
        }).then((res) => {
            // sort games by popularity
            res.games.sort((a, b) => {
                return (res.playCounts[b.appID] || 0) - (res.playCounts[a.appID] || 0);
            });

            // shuffle the first 5 games

            if (res.games.length > 5) {
                for (let i = 0; i < 5; i++) {
                    let j = Math.floor(Math.random() * 5);
                    let temp = res.games[i];
                    res.games[i] = res.games[j];
                    res.games[j] = temp;
                }
            }

            // filter possible null
            res.games = res.games.filter((game) => game.version != null);

            setGames(res.games);
            console.log(res.games);
        })
    }, [window.location.hash]);

    function handleCarouselIndexChange(index) {
        let carouselContainer = document.getElementById("spotlightCarouselContainer");
        carouselContainer.style.transform = `translateX(-${index * 100}%)`;
    }

    function reset() {
        setCarouselIndex(0);
        handleCarouselIndexChange(0);
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: 470,
                    maxWidth: "lg",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",

                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 2,
                            width: 900,
                            backgroundColor: "rgba(1, 1, 1, 0.1)",
                            position: "relative",
                            borderRadius: "2rem",
                            pt: 8,
                            aspectRatio: "73 / 45",
                        }}
                    >
                        <Typography
                            variant="h3"
                            sx={{ textAlign: "left", mt: -8, fontFamily: "Russo One" }}
                        >
                            {decodeURIComponent(window.location.hash.split("/").pop())}
                        </Typography>
                        <ButtonBase
                            onClick={() => {
                                if (carouselIndex > 0) {
                                    setCarouselIndex(carouselIndex - 1);
                                    handleCarouselIndexChange(carouselIndex - 1);
                                } else {
                                    setCarouselIndex(games.slice(0, 5).length - 1);
                                    handleCarouselIndexChange(games.slice(0, 5).length - 1);
                                }
                            }}
                            TouchRippleProps={{
                                style: {
                                    color: "rgba(255, 255, 255, 0.5)"
                                }
                            }}
                            sx={{
                                width: 60,
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                left: 0,
                                zIndex: 1,
                                "-webkit-mask-image": "linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%)",
                                "-webkit-mask-size": "100% 100%",
                                "-webkit-mask-repeat": "no-repeat",
                                borderRadius: 2,
                            }}
                        >
                            <ArrowBack />
                        </ButtonBase>

                        <div
                            style={{
                                height: "100%",
                                display: "box",
                                flexDirection: "row",
                                overflow: "hidden",
                                position: "absolute",
                                aspectRatio: "73 / 45",
                                alignSelf: "center",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: `${games.slice(0, 5).length * 100}%`,
                                    height: "100%",
                                    transition: "1s",
                                    transform: `translateX(-${(carouselIndex / games.slice(0, 5).length) * 100}%)`
                                }}
                                id="spotlightCarouselContainer"
                            >
                                {
                                    games.slice(0, 5).map((game) => {
                                        return (<SpotlightCarouselGame reset={reset} navigate={navigate} game={game} />)
                                    })
                                }
                            </div>
                        </div>

                        <ButtonBase
                            onClick={() => {
                                if (carouselIndex < games.slice(0, 5).length - 1) {
                                    setCarouselIndex(carouselIndex + 1);
                                    handleCarouselIndexChange(carouselIndex + 1);
                                } else {
                                    setCarouselIndex(0);
                                    handleCarouselIndexChange(0);
                                }
                            }}
                            TouchRippleProps={{
                                style: {
                                    color: "rgba(255, 255, 255, 0.5)"
                                }
                            }}
                            sx={{
                                width: 60,
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                right: 0,
                                zIndex: 1,
                                "-webkit-mask-image": "linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%)",
                                "-webkit-mask-size": "100% 100%",
                                "-webkit-mask-repeat": "no-repeat",
                                borderRadius: 2,
                            }}
                        >
                            <ArrowForward />
                        </ButtonBase>
                    </Box>
                </div>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "center",
                        position: "relative",
                        marginTop: 10,
                        gap: 1,
                    }}
                >
                    {games.slice(0, 5).map((g, i) => {
                        return (<div
                            style={{
                                width: 28,
                                height: 15,
                                borderRadius: 3,
                                backgroundColor: i == carouselIndex ? "rgba(255, 255, 255, 0.7)" : "rgba(255, 255, 255, 0.3)",
                                top: 10,
                                right: 10 + i * 20,
                                cursor: "pointer",
                                transition: "0.5s",
                            }}
                            onClick={() => {
                                setCarouselIndex(i);
                                handleCarouselIndexChange(i);
                            }}
                        />)
                    })}
                </Box>
                <Typography
                    variant="h4"
                    sx={{ textAlign: "left", marginTop: 2, fontFamily: "Russo One" }}
                >
                    All Games
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        position: "relative",
                        borderRadius: 2,
                        paddingBottom: 10,
                        flexWrap: "wrap",
                        overflowX: "hidden",
                        justifyContent: "center",
                    }}
                >
                    {
                        games.map((game) => {
                            return (
                                <GameCard game={game} navigate={navigate} />
                            )
                        })
                    }
                </Box>
            </Box>
        </div >
    );
};

export default Home;