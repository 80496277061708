import Button from '@mui/material/Button';
import { Component, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import util, { isElectron } from '../util';
import LinearGradient from '../components/LinearGradient';
import { Box, ButtonBase, Icon, IconButton, Tooltip, Typography } from '@mui/material';
import Tag from '../components/Tag';
import { ArrowBack, ArrowBackIos, ArrowForwardIos, InfoOutlined } from '@mui/icons-material';
import { useSwipeable } from 'react-swipeable';
import { isOffline, isServerDown, theme } from '../App';
import Image from '../components/Image';
import zIndex from '@mui/material/styles/zIndex';

export const GameCard = ({ game, type, navigate }) => {
    const [isHovered, setIsHovered] = useState(false);

    if (!game) {
        return <Typography
            variant="h5"
            style={{
                color: "white",
                textAlign: "left",
                marginBottom: 20,
                marginLeft: 20
            }}
        >
            SOMETHING HAS GONE REALLY WRONG AND I DON'T KNOW WHAT TO DO AAAAAAAAAAAA
        </Typography>
    }

    let version = Array.isArray(game?.versions) ? game?.versions[game?.versions.length - 1] : game?.versions || game.version

    return (
        <div
            style={{
                width: 338,
                height: 260,
                margin: 10,
                borderRadius: 10,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                justifyContent: "space-between",
                position: "relative",
                cursor: "pointer",
                flexShrink: 0,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => navigate(`/games/${game.appID}/${version.versionID}`)}
        >
            <div style={{ width: "100%", aspectRatio: "73 / 45", display: "flex", borderRadius: 10, overflow: 'hidden', position: 'relative' }}>
                <Image src={version?.thumbnail.url || version?.thumbnail} style={{
                    width: "100%", height: "100%", objectFit: "cover",
                    filter: isHovered ? "blur(5px) brightness(0.7)" : "none",
                    transition: "0.2s", position: "absolute", zIndex: 0,
                }} />
            </div>

            <div
                style={{
                    zIndex: 1,
                    position: "absolute",
                    bottom: 60, left: 10,
                }}
            >
                <div
                    style={{
                        height: isHovered ? 30 : 0,
                        overflow: "hidden",
                        transition: "0.5s",
                    }}
                >
                    {version?.tags.slice(0, 3).map((tag, i) => {
                        return <Tag key={i} text={tag?.name || tag} style={{ marginRight: 5 }} />
                    })}
                </div>
            </div>

            <div
                style={{
                    zIndex: 1,
                    position: "absolute",
                    top: 10,
                    width: "100%"
                }}
            >
                <div
                    style={{
                        backdropFilter: "blur(5px)",
                        width: "100%",
                        display: game.underReview ? "block" : "none",
                    }}
                >
                    <Typography variant="p" style={{ color: "red", fontSize: 16, textWrap: "wrap", margin: 0, lineHeight: 1, fontWeight: "bold" }}>GAME UNDER REVIEW<br />UNLISTED</Typography>
                </div>
            </div>

            <Typography variant="p" style={{ color: "white", zIndex: 1, textAlign: "left", fontSize: 16, position: "absolute", bottom: 0, left: 10, width: 260, height: 45, textWrap: "wrap" }}>{game.name}</Typography>
        </div>
    )
}

const HorizontalScroller = ({ children }) => {
    return (
        <div
            style={{
                overflow: "visible",
                whiteSpace: "nowrap",
                padding: 10,
                display: "inline-flex",
                width: "100%",
            }}
        >
            {children}
        </div>
    )
}

const HorizontalScrollerWithArrows = ({ games, navigate, cardWidth = 338, cardHeight = 240 }) => {
    const [scrollX, setScrollX] = useState(0);
    const [startScrollX, setStartScrollX] = useState(0);
    const maxScroll = -(games.length * cardWidth - (window.innerWidth > 1488 ? 1488 : window.innerWidth - cardWidth / 2) + cardWidth / 2); // 20 is padding

    const handlers = useSwipeable({
        onSwiping: (eventData) => {
            let newScrollX = startScrollX + eventData.deltaX;
            if (newScrollX < maxScroll) {
                newScrollX = maxScroll;
            } else if (newScrollX > 0) {
                newScrollX = 0;
            }
            setScrollX(newScrollX);
        },
        onSwiped: () => {
            setStartScrollX(scrollX);
        },
    });

    const allContentVisible = false//games.length * cardWidth <= window.innerWidth;

    return (
        <div
            style={{
                overflowX: "hidden",
                position: "relative",
            }}
        >
            <div
                style={{
                    transform: `translateX(${scrollX}px)`,
                    transition: "0.5s",
                }}
                {...handlers}
            >
                <HorizontalScroller>
                    {games.map((game, i) => {
                        return <GameCard key={i} game={game} navigate={navigate} />
                    })}
                </HorizontalScroller>
            </div>

            {!allContentVisible && scrollX < 0 && (
                <div
                    style={{
                        display: "flex",
                        position: "absolute",
                        top: 20,
                        justifyContent: "center",
                        width: 70,
                        height: cardHeight,
                        zIndex: 10,
                    }}
                >
                    <LinearGradient x1={0} y1={0} x2={0} y2={-1} color1='#00000000' color2='#151515ff' />
                    <IconButton
                        style={{
                            backgroundColor: "#00000000",
                            color: "white",
                            zIndex: 5,
                            position: "absolute",
                            alignSelf: "center",
                            justifyContent: "center",
                            height: cardHeight - 40,
                            top: 0,
                            width: 70,
                            borderRadius: 0,
                            "-webkit-mask-image": "linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%)",
                            "-webkit-mask-size": "100% 100%",
                            "-webkit-mask-repeat": "no-repeat",
                        }}
                        TouchRippleProps={{
                            style: {
                                color: "rgba(255, 255, 255, 0.5)"
                            }
                        }}
                        onClick={() => {
                            if (scrollX < 0) {
                                setScrollX(Math.min(scrollX + 676, 0));
                            }
                        }}
                    >
                        <ArrowBackIos />
                    </IconButton>
                </div>
            )}

            {!allContentVisible && scrollX > maxScroll && (<div
                style={{
                    display: "flex",
                    position: "absolute",
                    top: 20,
                    justifyContent: "center",
                    width: 70,
                    height: cardHeight,
                    zIndex: 10,
                    right: 0
                }}
            >
                <LinearGradient x1={0} y1={0} x2={0} y2={1} color1='#00000000' color2='#1c1c1cff' />
                <IconButton
                    style={{
                        backgroundColor: "#00000000",
                        color: "white",
                        zIndex: 5,
                        position: "absolute",
                        alignSelf: "center",
                        justifyContent: "center",
                        height: cardHeight - 40,
                        top: 0,
                        width: 70,
                        borderRadius: 0,
                        "-webkit-mask-image": "linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%)",
                        "-webkit-mask-size": "100% 100%",
                        "-webkit-mask-repeat": "no-repeat",
                    }}
                    TouchRippleProps={{
                        style: {
                            color: "rgba(255, 255, 255, 0.5)"
                        }
                    }}
                    onClick={() => {
                        if (scrollX > maxScroll) {
                            setScrollX(Math.max(scrollX - 676, maxScroll));
                        }
                    }}
                >
                    <ArrowForwardIos />
                </IconButton>
            </div>
            )}

        </div>
    )
}

const GameStripWithTitle = ({ title, games, navigate }) => {
    let tooltips = {
        "Popular Games": "These games are the most played games on the site when sorted by their recent play count",
        "New Games": "These games are the newest games on the site",
        "Recommended Games": "These games are recommended by the community, sorted by their community verdict and play count",
        "Recently Updated": "These games are the most recently updated games on the site",
    }

    return (
        <div>
            <ButtonBase
                disabled={["Popular Games", "New Games", "Recommended Games", "Recently Updated"].includes(title)}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    margin: -10,
                    marginTop: 0,
                    marginLeft: 10,
                    padding: 10,
                    borderRadius: 6,
                    pointerEvents: ["Popular Games", "New Games", "Recommended Games", "Recently Updated"].includes(title) ? "none" : "auto",
                }}
                onClick={() => {
                    navigate(`/tags/${encodeURIComponent(title)}`);
                }}
            >
                <Typography variant="h5" style={{
                    color: "white", textAlign: "left", display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: "Russo One",
                    fontSize: 22,
                    zIndex: 1,
                }}
                    onClick={() => {
                        navigate(`/tags/${encodeURIComponent(title)}`);
                    }}
                >{title}
                    {["Popular Games", "New Games", "Recommended Games", "Recently Updated"].includes(title) &&
                        <Tooltip sx={{
                            pointerEvents: "all"
                        }} title={tooltips[title]}>
                            <InfoOutlined
                                style={{
                                    color: "white",
                                    fontSize: 22,
                                    fontFamily: "Russo One",
                                    zIndex: 1,
                                    marginLeft: 10
                                }}
                            />
                        </Tooltip>}
                </Typography>
            </ButtonBase>

            <HorizontalScrollerWithArrows games={games} navigate={navigate} />
        </div>
    )
}

class GameCategoryRichCard extends Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false
    }

    render() {
        return (
            <ButtonBase
                key={this.props.i}
                sx={{
                    width: 250,
                    height: 250,
                    backgroundColor: "#252525",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    cursor: "pointer",
                }}
                onClick={() => {
                    this.props.navigate(`/tags/${encodeURIComponent(this.props.tagCategory.name)}`);
                }}
            >
                <Box
                    sx={{
                        width: 250,
                        height: 250,
                        display: "flex",
                        justifyContent: "center",
                        overflow: "hidden",
                        borderRadius: 10,
                        position: "relative",
                        "&:hover .overlay": {
                            // scale it up a bit on hover
                            backdropFilter: 'blur(0px)',
                            opacity: 0.7,
                        },
                        "&:hover .imageShow": {
                            transform: "rotateX(45deg) rotateY(0deg) rotateZ(325deg) scale(1.1)!important",
                        }
                    }}
                >
                    <Box
                        className="overlay"
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            transition: "0.3s ease-in-out",
                            background: "radial-gradient(circle at top left, transparent,rgba(0, 0, 0, 0.7))",
                            backdropFilter: 'blur(3px)',
                            zIndex: 1,
                        }}
                    />

                    <Typography
                        variant="h5"
                        style={{
                            color: "white",
                            textAlign: "center",
                            zIndex: 2,
                            position: "absolute",
                            bottom: 30,

                            width: "100%",
                            transform: "rotate(0deg)",
                            fontFamily: "Russo One",
                            pointerEvents: "none",
                        }}
                    >
                        {this.props.tagCategory.name}
                    </Typography>

                    <Box
                        className="imageShow"
                        sx={{
                            // skew it in 3d space as if we are looking at a wallpaper of images on the floor from above
                            transform: "rotateX(45deg) rotateY(0deg) rotateZ(325deg)",
                            display: "flex",
                            flexWrap: "wrap",
                            width: 480,
                            marginTop: "-90px",
                            marginLeft: "-100px",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            transition: "0.3s ease-in-out",
                        }}
                    >
                        {
                            // put the games's thumbnails in a square grid, put every game in the category, use game images if thumbnails are not enough
                            this.props.tagCategory.games.slice(0, 6).sort(() => Math.random() - 0.5).map((game, i) => {
                                return (
                                    <Image
                                        key={i}
                                        src={game.versions[game.versions.length - 1].thumbnail.url || game.versions[game.versions.length - 1].thumbnail}
                                        style={{
                                            aspectRatio: "73 / 45",
                                            width: 240,
                                            objectFit: "cover",
                                        }}
                                    />
                                )
                            })
                        }
                    </Box>
                </Box>
            </ButtonBase>
        )
    }
}

const Home = (props) => {
    const navigate = useNavigate();
    const [games, setGames] = useState([]);
    const [newGames, setNewGames] = useState([])
    const [popularGames, setPopularGames] = useState([])
    const [carouselItems, setCarouselItems] = useState([])
    const [recommendedGames, setRecommendedGames] = useState([])
    const [tagCategories, setTagCategories] = useState([]);
    const [updatedGames, setUpdatedGames] = useState([]);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [carouselImageOverrideURL, setCarouselImageOverrideURL] = useState("");
    const [carouselHalted, setCarouselHalted] = useState(false);

    function determineCarouselItems(popularity, verdictSort, sortedNew, g) {
        let featured = []
        let retries = 0;

        // get 7 games for the carousel, select from popular, recommended and new, and make sure they are unique and not already in the carousel
        while (featured.length < 6 && retries < 100) { // 100 retries to prevent infinite loop
            let game = popularity[Math.floor(Math.random() * 9)];
            game.reason = "Popular game";
            let preparedimages = []

            if (!featured.find((g) => g.appID === game.appID)) {
                // push images to preparedimages
                game.versions[game.versions.length - 1].images.forEach(image => {
                    if (!image.includes(".gif") && !image.includes(".mp4")) {
                        preparedimages.push(image)
                    }
                })

                if (preparedimages.length < 4) {
                    preparedimages = []
                    // skip this game if it doesn't have enough images
                    continue
                }

                game.versions[game.versions.length - 1].images = preparedimages

                preparedimages = []

                featured.push(game);
            }

            game = verdictSort[Math.floor(Math.random() * 9)];
            game.reason = "Recommended by the community";
            if (!featured.find((g) => g.appID === game.appID)) {
                game.versions[game.versions.length - 1].images.forEach(image => {
                    if (!image.includes(".gif") && !image.includes(".mp4")) {
                        preparedimages.push(image)
                    }
                })

                if (preparedimages.length < 4) {
                    preparedimages = []
                    // skip this game if it doesn't have enough images
                    continue
                }

                game.versions[game.versions.length - 1].images = preparedimages

                preparedimages = []

                featured.push(game);
            }

            game = sortedNew[Math.floor(Math.random() * 9)];
            game.reason = "New on the site";
            if (!featured.find((g) => g.appID === game.appID)) {
                game.versions[game.versions.length - 1].images.forEach(image => {
                    if (!image.includes(".gif") && !image.includes(".mp4")) {
                        preparedimages.push(image)
                    }
                })

                if (preparedimages.length < 4) {
                    preparedimages = []
                    // skip this game if it doesn't have enough images
                    continue
                }

                game.versions[game.versions.length - 1].images = preparedimages

                preparedimages = []

                featured.push(game);
            }

            // shuffle tags of the games
            featured.forEach(game => {
                game.versions[game.versions.length - 1].tags = game.versions[game.versions.length - 1].tags.sort(() => Math.random() - 0.5);
            })

            retries++;
        }

        // select a random game from the remaining games

        let rg = []

        while (rg.length < 2) {
            let sg = g[Math.floor(Math.random() * g.length)];
            let preparedimages = []
            if (!featured.find((g) => g.appID === sg.appID) && !rg.find((g) => g.appID === sg.appID)) {
                sg.versions[sg.versions.length - 1].images.forEach(image => {
                    if (!image.includes(".gif") && !image.includes(".mp4")) {
                        preparedimages.push(image)
                    }
                })

                if (preparedimages.length < 4) {
                    preparedimages = []
                    // skip this game if it doesn't have enough images
                    continue
                }

                sg.versions[sg.versions.length - 1].images = preparedimages

                preparedimages = []

                sg.reason = "Randomly selected";
                rg.push(sg);
            }
        }

        featured.push(...rg)

        featured = featured.sort(() => Math.random() - 0.5);

        setCarouselItems(featured);
    }

    useEffect(() => {
        const carouselInterval = setInterval(() => {
            if (!carouselHalted) {
                if (carouselIndex < carouselItems.length - 1) {
                    setCarouselIndex(carouselIndex + 1);
                } else {
                    setCarouselIndex(0);
                }
            }
        }, 10000);

        return () => clearInterval(carouselInterval);
    }, [carouselIndex, carouselItems, carouselHalted]);

    useEffect(() => {
        (async () => {
            await caches.open("request-cache").then(async (cache) => {
                let response = await cache.match("INDEX_GAMES");

                if (response && games.length === 0) { // only set if it hasn't been set by the network fetch
                    response = await response.json()
                    setGames(response);

                    let sortedNew = [...response].sort((a, b) => {
                        return new Date(b.created) - new Date(a.created);
                    })

                    setNewGames(sortedNew.slice(0, 10));

                    let popularity = sortedNew.sort((a, b) => {
                        return b.playCount - a.playCount;
                    })

                    setPopularGames(popularity.slice(0, 10));

                    let verdictSort = sortedNew.sort((a, b) => {
                        if (b.verdict === a.verdict) { // If verdicts are the same
                            return b.playCount - a.playCount; // Sort by playCount
                        }
                        return b.verdict - a.verdict; // Otherwise, sort by verdict
                    });

                    setRecommendedGames(verdictSort.slice(0, 10));

                    // sort games by their latest version's created date
                    let sortedUpdated = [...response].sort((a, b) => {
                        return new Date(b.versions[b.versions.length - 1].created) - new Date(a.versions[a.versions.length - 1].created);
                    })

                    setUpdatedGames(sortedUpdated.slice(0, 10));

                    determineCarouselItems(popularity, verdictSort, sortedNew, response);

                    let TagCategories = [];

                    response.forEach(game => {
                        game.versions.forEach(version => {
                            version.tags.forEach(tag => {
                                let found = false;
                                for (let i = 0; i < TagCategories.length; i++) {
                                    if (TagCategories[i].name === tag && !TagCategories[i].games.includes(game)) {
                                        TagCategories[i].games.push(game);
                                        found = true;
                                        break;
                                    }
                                }
                                if (!found && TagCategories.filter((category) => category.name === tag).length === 0) {
                                    TagCategories.push({
                                        name: tag,
                                        games: [game]
                                    })
                                }
                            })
                        })
                    })

                    TagCategories.sort((a, b) => {
                        return b.games.length - a.games.length;
                    })

                    TagCategories = TagCategories.slice(0, 10);

                    TagCategories.forEach(category => {
                        category.games = category.games.sort((a, b) => {
                            return b.playCount - a.playCount;
                        })
                    })

                    TagCategories = TagCategories.sort((a, b) => {
                        return b.games.length - a.games.length;
                    })

                    // shuffle the games in each category
                    TagCategories.forEach(category => {
                        category.games = category.games.sort(() => Math.random() - 0.5);
                    })

                    setTagCategories(TagCategories);
                }
            })
        })()

        util.getRequest(`/api/games${props.adminMode ? "?getall=true" : ""}`, (res) => {
            setGames(res.games);

            caches.open("request-cache").then(async (cache) => {
                cache.put("INDEX_GAMES", new Response(JSON.stringify(res.games)));
            })

            let sortedNew = [...res.games].sort((a, b) => {
                return new Date(b.created) - new Date(a.created);
            })

            setNewGames(sortedNew.slice(0, 15));

            let popularity = sortedNew.sort((a, b) => {
                return b.playCount - a.playCount;
            })

            setPopularGames(popularity.slice(0, 15));

            let verdictSort = sortedNew.sort((a, b) => {
                if (b.verdict === a.verdict) { // If verdicts are the same
                    return b.playCount - a.playCount; // Sort by playCount
                }
                return b.verdict - a.verdict; // Otherwise, sort by verdict
            });

            setRecommendedGames(verdictSort.slice(0, 15));

            // sort games by their latest version's created date
            let sortedUpdated = [...res.games].sort((a, b) => {
                return new Date(b.versions[b.versions.length - 1].created) - new Date(a.versions[a.versions.length - 1].created);
            })

            setUpdatedGames(sortedUpdated.slice(0, 15));

            determineCarouselItems(popularity, verdictSort, sortedNew, res.games);

            let TagCategories = [];

            res.games.forEach(game => {
                let version = game["versions"][game["versions"].length - 1];
                version.tags.forEach(tag => {
                    let found = false;
                    for (let i = 0; i < TagCategories.length; i++) {
                        if (TagCategories[i].name === tag && !TagCategories[i].games.includes(game)) {
                            TagCategories[i].games.push(game);
                            found = true;
                            break;
                        }
                    }
                    if (!found && TagCategories.filter((category) => category.name === tag).length === 0) {
                        TagCategories.push({
                            name: tag,
                            games: [game]
                        })
                    }
                })

            })

            TagCategories.sort((a, b) => {
                return b.games.length - a.games.length;
            })

            TagCategories = TagCategories.slice(0, 10);

            TagCategories.forEach(category => {
                category.games = category.games.sort((a, b) => {
                    return b.playCount - a.playCount;
                }).slice(0, 10);
            })

            TagCategories = TagCategories.sort((a, b) => {
                return b.games.length - a.games.length;
            })

            TagCategories.forEach(category => {
                category.games = category.games.sort(() => Math.random() - 0.5);
            })

            setTagCategories(TagCategories);
        });
    }, [isOffline]);

    if (isOffline) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'calc(100vh - 68.5px)'
                }}
            >
                <Typography variant="h3" component="h1" gutterBottom>
                    {isServerDown ? "Server Unreachable" : "You are offline"}
                </Typography>

                <Typography variant="p" gutterBottom>
                    {isServerDown ? "Whoops..." : "Please check your internet connection and try again."}
                </Typography>

                <Typography variant="p" gutterBottom>
                    Why not play a game while you wait?
                </Typography>

                <Button variant="contained" color="primary" onClick={() => {
                    navigate("/library");
                }}>
                    Let's Go!
                </Button>
            </Box>
        )
    }

    return (
        <div
            style={{
                marginTop: 20,
                position: "relative",
            }}
        >
            <Box>
                <Typography
                    variant="h5"
                    style={{
                        color: "white",
                        textAlign: "left",
                        marginBottom: 20,
                        marginLeft: 20,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        fontFamily: "Russo One",
                    }}
                >
                    Featured & Popular

                    <Tooltip title="Showcases successfull games from various categories, some games are randomly selected across the site too">
                        <InfoOutlined
                            style={{
                                color: "white",
                                fontSize: 22,
                                fontFamily: "Russo One",
                                zIndex: 1,
                                marginLeft: 10
                            }}
                        />
                    </Tooltip>
                </Typography>

                <Box
                    sx={{
                        width: "100%",
                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                        position: "relative"
                    }}
                    // set carouselHalted to true when the user hovers over the carousel
                    onMouseEnter={() => {
                        setCarouselHalted(true)
                    }}
                    onMouseLeave={() => {
                        setCarouselHalted(false)
                    }}
                >
                    <ButtonBase
                        sx={{
                            width: 45,
                            height: 100,
                            backgroundColor: "#252525",
                            alignSelf: "center",
                            display: "flex",
                            justifyContent: "center",
                            borderTopLeftRadius: 10,
                            borderBottomLeftRadius: 10,
                        }}
                        onClick={() => {
                            if (carouselIndex > 0) {
                                setCarouselIndex(carouselIndex - 1);
                            } else {
                                setCarouselIndex(carouselItems.length - 1);
                            }
                        }}
                    >
                        <ArrowBackIos
                            sx={{
                                marginLeft: "25%"
                            }}
                        />
                    </ButtonBase>

                    <Box
                        sx={{
                            minWidth: 850,
                            height: 405,
                            paddingBottom: "25px",
                            display: "flex",
                            position: "relative",
                        }}
                    >
                        <Image
                            src={carouselImageOverrideURL || carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.thumbnail.url || carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.thumbnail}
                            style={{
                                height: "100%",
                                aspectRatio: "73 / 45",
                                objectFit: carouselImageOverrideURL ? "contain" : "cover",
                                zIndex: 2,
                                // shadow on the right side
                                boxShadow: "20px 0px 20px -10px black",
                                backgroundColor: "#252525",
                                cursor: "pointer",
                            }}
                            onClick={() => navigate(`/games/${carouselItems[carouselIndex]?.appID}/${carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.versionID}`)}
                        />

                        <Box
                            sx={{
                                height: "100%",
                                display: "flex",
                                backgroundColor: "#252525",
                                width: "100%",
                                paddingRight: "3px",
                                paddingTop: 2.5,
                                paddingLeft: 2,
                                flexDirection: "column",
                            }}
                        >
                            <Typography
                                style={{
                                    color: "white",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    fontSize: 25,
                                    lineHeight: 1,
                                    width: 285,
                                    height: 50,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    cursor: "pointer",
                                }}
                                onClick={() => navigate(`/games/${carouselItems[carouselIndex]?.appID}/${carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.versionID}`)}
                            >
                                {carouselItems[carouselIndex]?.name}
                            </Typography>

                            <Box
                                sx={{
                                    width: "calc(100% + 1rem)",
                                    ml: -6,
                                    mt: 2
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "1%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            filter: "brightness(65%)", // Darken the image
                                            transition: "filter 0.3s",
                                            "&:hover": {
                                                filter: "brightness(100%)", // Normal brightness on hover
                                            },
                                        }}
                                    >
                                        <Image
                                            src={carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.images[0]}
                                            style={{
                                                aspectRatio: "16 / 9",
                                                borderRadius: 4,
                                                objectFit: "cover",
                                                maxHeight: 95,
                                            }}
                                            onMouseEnter={() =>
                                                setCarouselImageOverrideURL(
                                                    carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.images[0]
                                                )
                                            }
                                            onMouseLeave={() => setCarouselImageOverrideURL("")}
                                        />
                                    </Box>

                                    <Box
                                        sx={{
                                            filter: "brightness(65%)", // Darken the image
                                            transition: "filter 0.3s",
                                            "&:hover": {
                                                filter: "brightness(100%)", // Normal brightness on hover
                                            },
                                        }}
                                    >
                                        <Image
                                            src={carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.images[1]}
                                            style={{
                                                aspectRatio: "16 / 9",
                                                borderRadius: 4,
                                                objectFit: "cover",
                                                maxHeight: 95,
                                            }}
                                            onMouseEnter={() =>
                                                setCarouselImageOverrideURL(
                                                    carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.images[1]
                                                )
                                            }
                                            onMouseLeave={() => setCarouselImageOverrideURL("")}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "1%",
                                        mt: "-1%"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            filter: "brightness(65%)", // Darken the image
                                            transition: "filter 0.3s",
                                            "&:hover": {
                                                filter: "brightness(100%)", // Normal brightness on hover
                                            },
                                        }}
                                    >
                                        <Image
                                            src={carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.images[2]}
                                            style={{
                                                aspectRatio: "16 / 9",
                                                borderRadius: 4,
                                                objectFit: "cover",
                                                maxHeight: 95,
                                            }}
                                            onMouseEnter={() =>
                                                setCarouselImageOverrideURL(
                                                    carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.images[2]
                                                )
                                            }
                                            onMouseLeave={() => setCarouselImageOverrideURL("")}
                                        />
                                    </Box>

                                    <Box
                                        sx={{
                                            filter: "brightness(65%)", // Darken the image
                                            transition: "filter 0.3s",
                                            "&:hover": {
                                                filter: "brightness(100%)", // Normal brightness on hover
                                            },
                                        }}
                                    >
                                        <Image
                                            src={
                                                carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.images[3] ||
                                                carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.thumbnail
                                            }
                                            style={{
                                                aspectRatio: "16 / 9",
                                                borderRadius: 4,
                                                objectFit: "cover",
                                                maxHeight: 95,
                                            }}
                                            onMouseEnter={() =>
                                                setCarouselImageOverrideURL(
                                                    carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.images[3] ||
                                                    carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1]?.thumbnail
                                                )
                                            }
                                            onMouseLeave={() => setCarouselImageOverrideURL("")}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 0.5,
                                    mt: 2,
                                    flexWrap: "wrap",
                                    width: 270,
                                }}
                            >
                                {carouselItems[carouselIndex]?.versions[carouselItems[carouselIndex]?.versions.length - 1].tags.slice(0, 3).map((tag) => {
                                    return (
                                        <Button variant="contained" color="primary" sx={{ marginRight: 0.25, padding: 0, paddingRight: 0.25, paddingLeft: 0.25 }}
                                            onClick={() => {
                                                navigate("/tags/" + encodeURIComponent(tag))
                                            }}
                                        >{tag}</Button>
                                    )
                                })}
                            </Box>
                        </Box>

                        <div
                            style={{
                                width: "100%",
                                height: 15,
                                position: "absolute",
                                bottom: 0,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    height: 15,
                                    gap: 2,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {
                                    carouselItems.map((game, i) => {
                                        return <ButtonBase
                                            onClick={() => setCarouselIndex(i)}
                                            key={i}
                                            sx={{
                                                width: 25,
                                                height: 15,
                                                backgroundColor: carouselIndex === i ? "#606060" : "#252525",
                                                alignSelf: "center",
                                                borderRadius: 1
                                            }}
                                        />
                                    })
                                }
                            </Box>
                        </div>
                    </Box>

                    <ButtonBase
                        sx={{
                            width: 45,
                            height: 100,
                            backgroundColor: "#252525",
                            alignSelf: "center",
                            display: "flex",
                            justifyContent: "center",
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                        }}

                        onClick={() => {
                            if (carouselIndex < carouselItems.length - 1) {
                                setCarouselIndex(carouselIndex + 1);
                            } else {
                                setCarouselIndex(0);
                            }
                        }}
                    >
                        <ArrowForwardIos />
                    </ButtonBase>
                </Box>
            </Box>

            <Box>
                <Typography
                    variant="h5"
                    style={{
                        color: "white",
                        textAlign: "left",
                        marginBottom: 20,
                        marginLeft: 20,
                        fontFamily: "Russo One",
                    }}
                >
                    Games By Categories
                </Typography>

                <Box
                    sx={{
                        //width: "100%",
                        margin: 0,
                        display: "flex",
                        position: "relative",
                        gap: 3,
                        overflowX: "auto",
                        pb: "5px",
                        '&::-webkit-scrollbar': {

                            height: '6px',
                        },
                    }}
                >
                    {tagCategories.map((tagCategory, i) => {
                        if (tagCategory.games.length < 6) return null;
                        return (
                            <GameCategoryRichCard key={i} navigate={navigate} tagCategory={tagCategory} />
                        )
                    })}
                </Box>
            </Box>

            <GameStripWithTitle navigate={navigate} title="Popular Games" games={popularGames} />
            <GameStripWithTitle navigate={navigate} title="New Games" games={newGames} />
            <GameStripWithTitle navigate={navigate} title="Recommended Games" games={recommendedGames} />
            <GameStripWithTitle navigate={navigate} title="Recently Updated" games={updatedGames} />
            {
                tagCategories.map((tagCategory, i) => {
                    return (
                        <GameStripWithTitle key={i} navigate={navigate} title={tagCategory.name} games={tagCategory.games} />
                    )
                })
            }
        </div>
    );
};

export default Home;