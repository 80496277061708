import { TextField, Typography, Button, Box, Grid, Link, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthSubbar from '../components/authSubbar';
import Util from "../util"

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [snackVisible, setSnackVisible] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    const handleSignup = async (event) => {
        event.preventDefault();

        // Perform actual login logic here (e.g., API call)
        try {
            let response = await Util.postRequest("/api/auth/signup", { username, password, email });

            response = await response.json();

        } catch (error) {
            setError('An error occurred during login. Please try again later.');
        }
    };

    return (
        <Box component="form"
            onSubmit={handleSignup}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 68.5px)'
            }}>
            <Snackbar
                open={snackVisible}
                autoHideDuration={6000}
                onClose={() => setSnackVisible(false)}
                message={snackMessage}
            />
            <Typography variant="h3" component="h1" gutterBottom>
                Register
            </Typography>
            <TextField
                label="Username"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
                required
                error={error && error.includes('username')}
                helperText={error && error.includes('username') ? error : ''}
            />
            <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                required
                error={error && error.includes('email')}
                helperText={error && error.includes('email') ? error : ''}
                type="email"
            />
            <TextField
                label="Password"
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                required
                error={error && error.includes('password')}
                helperText={error && error.includes('password') ? error : ''}
            />
            <Button variant="contained" color="primary" type="submit">
                Register
            </Button>
            <Typography variant="a" onClick={() => { navigate("/login") }} color={"primary"} sx={{ mt: 2, textDecoration: "underline", cursor: "pointer" }}>
                Already have an account? Login here
            </Typography>

            <AuthSubbar />
        </Box>
    );
};

export default Register;