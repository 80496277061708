import Button from '@mui/material/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import util from '../util';
import { Box, CircularProgress, Typography } from '@mui/material';

const AgeCheck = (props) => {
    const navigate = useNavigate();
    const [isFalse, setIsFalse] = useState(false);
    const [isTrue, setIsTrue] = useState(false);

    if (isFalse) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <Typography
                    variant="h2"
                    component="h1"
                    gutterBottom
                    sx={{
                        position: 'absolute',
                        top: "5%",
                    }}
                >
                    Age Check
                </Typography>

                <Typography
                    variant="h2"
                    component="h1"
                    gutterBottom
                >
                    Sorry :(
                </Typography>

                <Typography
                    variant="body2"
                    component="h1"
                    gutterBottom
                    sx={{
                        marginTop: '20px',
                        fontWeight: 'bold',
                        fontSize: '1.5rem',
                    }}
                >
                    You are not allowed to view adult content.
                </Typography>

                <Typography
                    variant="body1"
                    component="h1"
                    gutterBottom
                    sx={{
                        marginTop: '20px',
                        fontSize: '1.2rem',
                    }}
                >
                    Please come back when you are at a legal age to view and interact with such content.
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Typography
                variant="h2"
                component="h1"
                gutterBottom
                sx={{
                    position: 'absolute',
                    top: "5%",
                }}
            >
                Age Check
            </Typography>

            <Typography
                variant="h2"
                component="h1"
                gutterBottom
            >
                Welcome to Vorecade!
            </Typography>

            {
                isTrue ?
                    <Box
                        sx={{
                            display: "flex",
                            gap: 3,
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 5
                        }}
                    >
                        <Typography
                            variant="body2"
                            component="h1"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.5rem',
                            }}
                        >
                           Letting you in...
                        </Typography>

                        <CircularProgress/>
                    </Box>
                    :
                    <Box>
                        <Typography
                            variant="body2"
                            component="h1"
                            gutterBottom
                            sx={{
                                marginTop: '20px',
                                fontWeight: 'bold',
                                fontSize: '1.5rem',
                            }}
                        >
                            We need to ask you a quick question before you can proceed.
                        </Typography>

                        <Typography
                            variant="body1"
                            component="h1"
                            gutterBottom
                            sx={{
                                marginTop: '20px',
                                fontSize: '1.2rem',
                            }}
                        >
                            Are you legally allowed to view adult content?
                        </Typography>

                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            This site includes content for adult audience. Please confirm that you are at a legal age to view and interact with such content.
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 8,
                                marginTop: '20px',
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setIsTrue(true);
                                    setTimeout(() => {
                                        localStorage.setItem("ageCheck", "true");
                                        props.setIsAgeChecked(true)
                                    }, 1000);
                                }}
                            >
                                Yes
                            </Button>

                            <Button
                                variant="contained"
                                onClick={() => {
                                    setIsFalse(true);
                                }}
                            >
                                No
                            </Button>
                        </Box>
                    </Box>}
        </Box>
    );
};

export default AgeCheck;