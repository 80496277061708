import { ErrorOutline, Warning } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { snackCaller } from '../App';
import util from '../util';

function Image(props) {
    const { esrc, ...other } = props;
    const [error, setError] = React.useState(null);
    const [dsrc, setDsrc] = React.useState(null);

    React.useEffect(() => {
        if (!esrc && !props.src) {
            return;
        }

        if (dsrc) {
            URL.revokeObjectURL(dsrc);
        }

        const cacheName = 'image-cache';

        async function fetchAndCacheImage(url) {
            const cache = await caches.open(cacheName);
            const cachedResponse = await cache.match(url);

            if (cachedResponse) {
                return cachedResponse;
            }

            const networkResponse = await fetch(url);
            if (networkResponse.ok) {
                try {
                cache.put(url, networkResponse.clone()).catch((e)=>{})
                } catch(e){}
                return networkResponse;
            } else {
                throw new Error('Network response was not ok');
            }
        }

        fetchAndCacheImage(props.src).then(async (res) => {
            setDsrc(URL.createObjectURL(await res.blob()));
        }).catch((e) => {
            setDsrc(props.src)
        });
    }, [props.src, esrc]);

    React.useEffect(() => {
        // cleanup

        return () => {
            if (dsrc) {
                URL.revokeObjectURL(dsrc); // free memory, it's not just low level programmers that suffer from memory leaks
            }
        }
    }, [])

    if (!props.src) {
        return (
            <></>
        )
    }

    if (!dsrc && !error) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...props.style
                }}
            >
                <CircularProgress size={40} color="primary" />
            </div>
        )
    }

    if (error) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    fontSize: 50
                }}
            >
                <Warning
                    color="error"
                    fontSize="inherit"
                />
            </div>
        )
    }

    return (
        <img src={dsrc} {...other} />
    );
}


export default Image;