import { TextField, Typography, Button, Box, Grid, Link, Snackbar, IconButton, Dialog, FormControl, FormControlLabel, Switch, Select, MenuItem, Modal, DialogContent, DialogActions, DialogContentText, ButtonBase, createTheme, DialogTitle, createFilterOptions, Autocomplete } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthSubbar from '../components/authSubbar';
import Util, { isElectron } from "../util"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { Check, Edit } from '@mui/icons-material';
import { callLoadingLine, setTheme, snackCaller, theme } from '../App';
import MarkdownEditor from '../components/MarkdownEditor';
import CDNBrowser from "./CDN/index";
import QRCode from 'react-qr-code';
import Image from '../components/Image';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const filter = createFilterOptions()

const UserSettings = () => {
    const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("user") || "false") || {});
    const [about, setAbout] = React.useState(user.about || `# Markdown Editor
## Welcome! to the Vorecade Markdown Editor!

This is a simple markdown editor that allows you to write markdown on the left side and see the live preview on the right side.

***Github Flavored Markdown*** is supported, if you know you know 😎
if you don't know much about markdown, you can learn more [here](https://www.markdownguide.org/cheat-sheet/)

Here's some sample Markdown code to get you started:

Copy and paste the following code into the markdown editor and get started!

\`\`\`markdown
# Hey! This is a heading
## This is a subheading

* This is a list item
* This is another list item

- This is also a list item

1. This is a numbered list item
2. This is another numbered list item

This is a paragraph with some **bold** and *italic* text.

[This is a link](https://vorecade.com)

![This is an image](/logo192.png)

| Feature  | Is Done? | will be done?
|-|-|-|
| Vorecade  | no | probably not
| Bugs   | absolutely. | YES
\`\`\`
`);

    React.useEffect(() => {
        Util.postRequest("/api/auth").then((res) => {
            if(res) {
                setUser(res.user)
                localStorage.setItem("user", JSON.stringify(res.user))
            }
        })

        window.ipcRenderer.invoke("getClientVersionID").then((res) => {
            setCurrentChannel(res.currentChannel.toString())
        })

        Util.postRequest("/api/gcuc", {}).then(async (res) => {
            setVersionChannels(res.map((channel) => { return channel.name }))
        })
    }, []);


    const navigate = useNavigate();
    const [snackVisible, setSnackVisible] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [value, setValue] = React.useState(0);
    const [changeTarget, setChangeTarget] = React.useState("")
    const [twoFactorQRVisible, setTwoFactorQRVisible] = React.useState(false);
    const [qrCodeValue, setQRCodeValue] = React.useState("");
    const [twoFactorCompletionModalVisible, setTwoFactorCompletionModalVisible] = React.useState(false);
    const [twoFactorCompletionCode, setTwoFactorCompletionCode] = React.useState("");
    const [ssmPasswordModalVisible, setSSMPasswordModalVisible] = React.useState(false);
    const [ssmPassword, setSSMPassword] = React.useState("");
    const [passwordChangeDialogVisible, setPasswordChangeDialogVisible] = React.useState(false);
    const [passwordChange, setPasswordChange] = React.useState({
        oldPassword: "",
        newPassword: "",
        newPasswordRepeat: "",
    });
    const [tagOptions, setTagOptions] = React.useState([])
    const [dislikedTags, setDislikedTags] = React.useState(user.dislikedTags || [])
    const [versionChannels, setVersionChannels] = React.useState([])
    const [currentChannel, setCurrentChannel] = React.useState("Stable")
    const [cakeDayDialogVisible, setCakeDayDialogVisible] = React.useState(false)
    const [cakeDay, setCakeDay] = React.useState(user.cakeDay || "")
    const [cakeDayDay, setCakeDayDay] = React.useState(1)
    const [cakeDayMonth, setCakeDayMonth] = React.useState(1)

    const fileInputRef = React.useRef(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAvatarUploadClick = () => {
        setChangeTarget("changeAvatar")
        fileInputRef.current.click();
    }

    const handleFileChange = (event) => {
        Util.handleFileDrop(event).then(file => {
            Util.postRequest("/api/user/" + changeTarget, { file: file.vcid }).then((res) => {
                setUser({ ...user, avatar: res.avatar });
                setSnackMessage("Avatar changed");
                setSnackVisible(true);<Box>
                <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                    Single Session Mode
                </Typography>

                <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                    Enable single session mode to prevent multiple logins at the same time. If you enable this, you'll be logged out from other devices.
                </Typography>

                <FormControl style={{ marginLeft: 20 }} >
                    <FormControlLabel control={<Switch checked={user.singleSession} onChange={(e) => {
                        if (!e.target.checked) {
                            setSSMPasswordModalVisible(true)
                        } else {
                            Util.postRequest("/api/user/changeSingleSession", { password: "" }).then((res) => {
                                setUser({ ...user, singleSession: res.singleSession });
                                localStorage.setItem("user", JSON.stringify({ ...user, singleSession: res.singleSession }));
                            }).catch((err) => {
                                snackCaller("Invalid password")
                            })
                        }
                    }} />} label={user.singleSession ? "Enabled" : "Disabled"} />
                </FormControl>
            </Box>
            })
        })
    };

    return (
        <Box sx={{ width: '100%', marginTop: 5 }}>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 80,
                    backgroundColor: '#202020',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: "flex-start",
                        marginLeft: 20,
                    }}
                >
                    <Image src={user.avatar || "https://cdn.vorecade.com/defaults/avatar.webp"} style={{ width: 60, height: 60, borderRadius: 50 }} />
                    <Typography variant="h5" component="h1" style={{ marginLeft: 20 }}>
                        {user.username}
                    </Typography>
                </div>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Profile" />
                    <Tab label="Privacy" />
                    <Tab label="Account" />
                    <Tab label="Client" />
                    <Tab label="Content Control" />
                </Tabs>
            </Box>


            <CustomTabPanel value={value} index={0}>
                <Box
                    sx={{
                        textAlign: "left",
                        maxWidth: "xs",
                        width: "100%",
                    }}
                >
                    <Typography variant="h5" component="h1" style={{ marginLeft: 20 }}>
                        About Me
                    </Typography>
                    <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                        Set your profile picture, about yourself and other details. These will represent you across the site. We recommend you to not write any personally identifiable information here.<br />
                        You cannot change your username. If you need to change your username, please contact support.
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: 100,
                            backgroundColor: '#202020',
                            padding: 2,
                            marginTop: 2
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 100,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "flex-end",
                                    marginLeft: 20,
                                    position: "relative",
                                    width: 70,
                                    height: 70,
                                }}
                            >
                                <Image src={user.avatar || "https://cdn.vorecade.com/defaults/avatar.webp"} alt="avatar" style={{ objectFit: "cover", zIndex: 1, width: 70, height: 70, borderRadius: "50%", }} />
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        width: 70,
                                        height: 70,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: `0px 0px 5px 3px ${user.isOnline ? "#00ff00" : "red"}`,
                                        zIndex: 0
                                    }}
                                />
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        width: 30,
                                        height: 30,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "0px 0px 5px 0px black",
                                        zIndex: 2,
                                        display: user.username === JSON.parse(localStorage.getItem("user") || "{}").username ? "flex" : "none"
                                    }}
                                >
                                    <IconButton
                                        onClick={handleAvatarUploadClick}
                                        color="primary"
                                    >
                                        <Edit />
                                    </IconButton>
                                </div>
                            </div>

                            <Typography variant="h5" component="h1" style={{ marginLeft: 20 }}>
                                {user.username}
                            </Typography>

                        </div>
                    </Box>

                    <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                        About Me
                    </Typography>

                    <MarkdownEditor
                        value={about}
                        onChange={(value) => {
                            setUser({ ...user, about: value });
                            setAbout(value);
                        }}
                    />

                    <Button
                        variant="contained"
                        onClick={() => {
                            setUser({});
                            Util.postRequest("/api/user/changeAbout", { about: user.about }).then((res) => {
                                setUser({ ...user, about: res.about });
                                setAbout(res.about);
                            })
                        }}
                        sx={{
                            margin: 2
                        }}
                    >
                        Save
                    </Button>


                </Box>

            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <Box
                    sx={{
                        textAlign: "left",
                        maxWidth: "xs",
                        width: "100%",
                    }}
                >
                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Profile Visibility
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Set your profile visibility. If you set it to private, only you can see your profile.
                        </Typography>


                        <FormControl style={{ marginLeft: 20 }} >
                            <FormControlLabel control={<Switch checked={user.profileVisibility} onChange={(e) => {
                                Util.postRequest("/api/user/changeVisibility", { profileVisibility: e.target.checked }).then((res) => {
                                    setUser({ ...user, profileVisibility: res.profileVisibility });
                                    localStorage.setItem("user", JSON.stringify({ ...user, profileVisibility: res.profileVisibility }));
                                })
                            }} />} label={user.profileVisibility ? "Public" : "Private"} />
                        </FormControl>
                    </Box>

                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Help game developers
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Help developers by sending your anonymous gameplay data such as playtime, actions and more. Your data will only be shared with the developers of the games you play.
                            <br />
                            <strong>Data includes: The game you're playing, the action you're taking, timestamps and crashlogs. (e.g: launching, updating, removing etc.)</strong><br />
                            <i style={{ fontSize: 10 }}>Some basic information such as install statistics still will be collected to populate our popular games directory.</i>
                        </Typography>

                        <FormControl style={{ marginLeft: 20 }} >
                            <FormControlLabel control={<Switch checked={user.dataCollectionOptOut} onChange={async (e) => {
                                Util.postRequest("/api/user/changeDataCollection", { dataCollectionOptOut: e.target.checked }).then((res) => {
                                    setUser({ ...user, dataCollectionOptOut: res.dataCollectionOptOut });
                                    localStorage.setItem("user", JSON.stringify({ ...user, dataCollectionOptOut: res.dataCollectionOptOut }));
                                })

                                if (isElectron()) {
                                    let res = await window.ipcRenderer.invoke("showDecisionBox", {
                                        title: "Restart Required",
                                        message: "We need to restart the client to apply your changes. Do you want to restart now?",
                                    })

                                    if (res) {
                                        window.ipcRenderer.invoke("restartClient");
                                    }
                                }
                            }} />} label={user.dataCollectionOptOut ? "Don't send anonymous analytics" : "Send anonymous analytics"} />
                        </FormControl>
                    </Box>
                </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <Box
                    sx={{
                        textAlign: "left",
                        maxWidth: "xs",
                        width: "100%",
                    }}
                >
                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Language (coming soon)
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Set your language. This will affect the site and every client where you are logged in.
                        </Typography>

                        <Select
                            value={"en"}
                            style={{ marginLeft: 20, marginTop: 15 }}
                            onChange={(e) => {
                                return
                                Util.postRequest("/api/user/changeLanguage", { language: e.target.value }).then((res) => {
                                    setUser({ ...user, language: res.language });
                                })
                            }}
                        >
                            <MenuItem value={"en"}>English</MenuItem>
                        </Select>
                    </Box>

                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Single Session Mode
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Enable single session mode to prevent multiple logins at the same time. If you enable this, you'll be logged out from other devices.
                        </Typography>

                        <FormControl style={{ marginLeft: 20 }} >
                            <FormControlLabel control={<Switch checked={user.singleSession} onChange={(e) => {
                                if (!e.target.checked) {
                                    setSSMPasswordModalVisible(true)
                                } else {
                                    Util.postRequest("/api/user/changeSingleSession", { password: "" }).then((res) => {
                                        setUser({ ...user, singleSession: res.singleSession });
                                        localStorage.setItem("user", JSON.stringify({ ...user, singleSession: res.singleSession }));
                                    }).catch((err) => {
                                        snackCaller("Invalid password")
                                    })
                                }
                            }} />} label={user.singleSession ? "Enabled" : "Disabled"} />
                        </FormControl>
                    </Box>

                    <Dialog
                        open={ssmPasswordModalVisible}
                        onClose={() => {
                            setSSMPasswordModalVisible(false)
                            setSSMPassword("")
                        }}
                    >
                        <DialogContent>
                            <Typography variant="h5" component="h1">
                                Please enter your password to turn off Single Session Mode
                            </Typography>

                            <TextField
                                sx={{
                                    mt: 2
                                }}
                                fullWidth
                                type={"password"}
                                onChange={(e) => {
                                    setSSMPassword(e.target.value)
                                }}
                                label="Password"
                                value={ssmPassword}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setSSMPasswordModalVisible(false)
                                    setSSMPassword("")
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                onClick={() => {
                                    Util.postRequest("/api/user/changeSingleSession", { password: ssmPassword }).then((res) => {
                                        setSSMPasswordModalVisible(false)
                                        setSSMPassword("")

                                        setUser({ ...user, singleSession: res.singleSession });
                                        localStorage.setItem("user", JSON.stringify({ ...user, singleSession: res.singleSession }));
                                    }).catch((err) => {
                                        snackCaller("Invalid password")
                                    })
                                }}
                            >
                                Change SSM Mode
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Change Password
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Change your password. We recommend you to use a strong password.
                        </Typography>

                        <Button
                            variant="contained"
                            onClick={() => {
                                setPasswordChangeDialogVisible(true)
                            }}
                            style={{
                                margin: 20
                            }}
                        >
                            Change Password
                        </Button>
                    </Box>

                    <Dialog
                        open={passwordChangeDialogVisible}
                        onClose={() => {
                            setPasswordChangeDialogVisible(false)
                            setPasswordChange({
                                oldPassword: "",
                                newPassword: "",
                            })
                        }}
                    >
                        <DialogTitle>
                            Change Password
                        </DialogTitle>

                        <DialogContent>
                            <TextField
                                sx={{
                                    mt: 2,
                                    mb: 2
                                }}
                                fullWidth
                                type={"password"}
                                onChange={(e) => {
                                    setPasswordChange({ ...passwordChange, oldPassword: e.target.value })
                                }}
                                label="Old Password"
                                value={passwordChange.oldPassword}
                            />

                            <TextField
                                sx={{
                                    mt: 2,
                                    mb: 2
                                }}
                                fullWidth
                                type={"password"}
                                onChange={(e) => {
                                    setPasswordChange({ ...passwordChange, newPassword: e.target.value })
                                }}
                                label="New Password"
                                value={passwordChange.newPassword}
                            />

                            <TextField
                                sx={{
                                    mt: 2,
                                    mb: 2
                                }}
                                fullWidth
                                type={"password"}
                                onChange={(e) => {
                                    setPasswordChange({ ...passwordChange, newPasswordRepeat: e.target.value })
                                }}
                                label="New Password Repeat"
                                value={passwordChange.newPasswordRepeat}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setPasswordChangeDialogVisible(false)
                                    setPasswordChange({
                                        oldPassword: "",
                                        newPassword: "",
                                        newPasswordRepeat: "",
                                    })
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                onClick={() => {
                                    if (passwordChange.newPassword !== passwordChange.newPasswordRepeat) {
                                        snackCaller("Passwords do not match")
                                        return
                                    }

                                    Util.postRequest("/api/user/changePassword", { oldPassword: passwordChange.oldPassword, newPassword: passwordChange.newPassword }).then((res) => {
                                        if (res.success) {
                                            setPasswordChangeDialogVisible(false)
                                            setPasswordChange({
                                                oldPassword: "",
                                                newPassword: "",
                                                newPasswordRepeat: "",
                                            })
                                            snackCaller("Password changed")
                                        }
                                    })
                                }}
                                variant="contained"
                            >
                                Change Password
                            </Button>
                        </DialogActions>

                    </Dialog>

                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Cake Day 🍰
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Set your personal cake day. This date won't be shown on your profile or to anyone else, your profile will be decorated with a cake on this day and some celebratory effects will be shown. You can set the celebratory effects to be public if you'd like.<br /><strong>Your account's cake day will be celebrated publicly.</strong><br /><strong>You'll be able to remove your personal cake day if you'd like to.</strong>
                        </Typography>

                        {user.birthDateString && <Typography variant="body2" component="p" style={{ marginLeft: 20 }}>
                            Your current cake day is {user.birthDateString} 🍰 (MM/DD)</Typography>}

                        <Button
                            variant="contained"
                            onClick={() => {
                                setCakeDayDialogVisible(true)
                            }}
                            style={{
                                margin: 20
                            }}
                        >
                            Set Cake Day 🍰
                        </Button>

                        <Dialog
                            open={cakeDayDialogVisible}
                            onClose={() => {
                                setCakeDayDialogVisible(false)
                                setCakeDay("")
                            }}
                        >
                            <DialogTitle>
                                Set Cake Day 🍰
                            </DialogTitle>

                            <DialogContent
                                sx={{
                                    width: 260,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 2
                                    }}
                                >
                                    <Select
                                        value={cakeDayMonth}
                                        onChange={(e) => {
                                            setCakeDayMonth(e.target.value)
                                        }}
                                        label="Month"
                                        fullWidth
                                    >
                                        <MenuItem value={1}>January</MenuItem>
                                        <MenuItem value={2}>February</MenuItem>
                                        <MenuItem value={3}>March</MenuItem>
                                        <MenuItem value={4}>April</MenuItem>
                                        <MenuItem value={5}>May</MenuItem>
                                        <MenuItem value={6}>June</MenuItem>
                                        <MenuItem value={7}>July</MenuItem>
                                        <MenuItem value={8}>August</MenuItem>
                                        <MenuItem value={9}>September</MenuItem>
                                        <MenuItem value={10}>October</MenuItem>
                                        <MenuItem value={11}>November</MenuItem>
                                        <MenuItem value={12}>December</MenuItem>
                                    </Select>

                                    <Select
                                        value={cakeDayDay}
                                        onChange={(e) => {
                                            setCakeDayDay(e.target.value)
                                        }}
                                        label="Day"
                                        fullWidth
                                    >
                                        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => {
                                            return <MenuItem value={day}>{day}</MenuItem>
                                        })}
                                    </Select>
                                </Box>


                            </DialogContent>

                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        setCakeDayDialogVisible(false)
                                        setCakeDay("")
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    onClick={() => {
                                        Util.postRequest("/api/user/setBirthday", {
                                            birthDateString: `${cakeDayDay}/${cakeDayMonth}`
                                        }).then((res) => {
                                            setCakeDayDialogVisible(false)
                                            setUser({ ...user, birthDateString: `${cakeDayDay}/${cakeDayMonth}` });
                                            localStorage.setItem("user", JSON.stringify({ ...user, birthDateString: `${cakeDayDay}/${cakeDayMonth}` }));
                                            setCakeDay("")

                                            snackCaller("Cake Day set")
                                        })
                                    }}
                                    variant="contained"
                                >
                                    Set Cake Day
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>

                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Cake Day Publicity
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Set your cake day celebrations to be public. This'll allow anyone who views your profile to see your cake day celebrations.
                            <br/>
                            <strong>Your personal cake day's date won't be visible.</strong>
                        </Typography>

                        <FormControl style={{ marginLeft: 20 }} >
                            <FormControlLabel control={<Switch checked={user.isBirthDatePublic} onChange={(e) => {
                                Util.postRequest("/api/user/setBirthdayVisibility", { state: e.target.checked })
                                setUser({ ...user, isBirthDatePublic: e.target.checked });
                                localStorage.setItem("user", JSON.stringify({ ...user, isBirthDatePublic: e.target.checked }));

                            }} />} label={user.isBirthDatePublic ? "Public" : "Private"} />
                        </FormControl>
                    </Box>

                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Discord Connection (coming soon)
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Connect your Discord account to your Vorecade account. This will allow you to use premium features (if subscribed) on Vorecade.
                        </Typography>

                        <Typography variant="body2" component="p" style={{ marginLeft: 20 }}>
                            <strong>You need to join Vorecade's Discord server to use premium features.</strong><br />You can join the server by clicking <Link href="https://discord.gg/fvjfp8NJMg" target="_blank">here</Link>.
                        </Typography>

                        <Modal
                            open={twoFactorQRVisible}
                            onClose={() => {
                                setTwoFactorQRVisible(false)
                                callLoadingLine(-1, "")
                                setQRCodeValue("")
                                snackCaller("2FA setup cancelled")
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 355,
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                    borderRadius: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "white",
                                        padding: 2,
                                        borderRadius: 2,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <QRCode value={qrCodeValue} />
                                </Box>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setTwoFactorQRVisible(false)
                                        setTwoFactorCompletionModalVisible(true)
                                        callLoadingLine(50, "Please enter the code from your Authenticator App.")
                                        setQRCodeValue("")
                                    }}
                                    sx={{
                                        marginTop: 3
                                    }}
                                >
                                    I've scanned the QR code
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setTwoFactorQRVisible(false)
                                        callLoadingLine(-1, "")
                                        setQRCodeValue("")
                                        snackCaller("2FA setup cancelled")
                                    }}
                                    sx={{
                                        marginTop: 3
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Modal>

                        <Modal
                            open={twoFactorCompletionModalVisible}
                            onClose={() => {
                                setTwoFactorCompletionModalVisible(false)
                                callLoadingLine(-1, "")
                                setTwoFactorCompletionCode("")
                                snackCaller("2FA setup cancelled")
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 440,
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                    borderRadius: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography variant={"body1"}>Please enter the code from your Authenticator App.</Typography>

                                <TextField
                                    sx={{
                                        mt: 2
                                    }}
                                    type={"number"}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value.length <= 6) {
                                            setTwoFactorCompletionCode(value);
                                        }
                                    }}
                                    // set max length to 6
                                    inputProps={{ style: { textAlign: "center" } }}
                                    label="Code"
                                    value={twoFactorCompletionCode}
                                />

                                <Button
                                    variant="contained"
                                    disabled={twoFactorCompletionCode.length !== 6}
                                    onClick={() => {
                                        callLoadingLine(75, "Verifying...")

                                        setTimeout(() => {
                                            Util.postRequest("/api/user/enable2FA", { code: twoFactorCompletionCode }).then((res) => {
                                                callLoadingLine(-1, "")
                                                setTwoFactorCompletionModalVisible(false)
                                                setTwoFactorCompletionCode("")
                                                snackCaller("2FA setup completed")
                                            }).catch((err) => {
                                                callLoadingLine(50, "Please enter the code from your Authenticator App.")
                                                setTwoFactorCompletionCode("")
                                                snackCaller("Invalid code")
                                            })
                                        }, 250);
                                    }}
                                    sx={{
                                        marginTop: 3
                                    }}
                                >
                                    Verify
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setTwoFactorCompletionModalVisible(false)
                                        callLoadingLine(-1, "")
                                        setTwoFactorCompletionCode("")
                                        snackCaller("2FA setup cancelled")
                                    }}
                                    sx={{
                                        marginTop: 3
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Modal>

                        <Button
                            disabled
                            variant="contained"
                            onClick={() => {
                                Util.postRequest("/api/user/setup2FA").then((res) => {
                                    callLoadingLine(0, "Please scan the QR code on your Authenticator App.")
                                    setQRCodeValue(res.secret)
                                    setTwoFactorQRVisible(true)
                                })
                            }}
                            sx={{
                                margin: 2
                            }}
                        >
                            Connect Discord
                        </Button>
                    </Box>

                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            2FA (2 Factor Authentication) (coming soon)
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Set up 2FA to secure your account. You'll be prompted to enter a TOTP code every time you login. You can use any authenticator app like Authy.
                        </Typography>

                        <Typography variant="body2" component="p" style={{ marginLeft: 20 }}>
                            <strong>We cannot recover an account if you lose your 2FA and recovery code credentials. Please keep them in a secure place.</strong><br />You can disable 2FA at any time in the settings.
                        </Typography>

                        <Modal
                            open={twoFactorQRVisible}
                            onClose={() => {
                                setTwoFactorQRVisible(false)
                                callLoadingLine(-1, "")
                                setQRCodeValue("")
                                snackCaller("2FA setup cancelled")
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 355,
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                    borderRadius: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "white",
                                        padding: 2,
                                        borderRadius: 2,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <QRCode value={qrCodeValue} />
                                </Box>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setTwoFactorQRVisible(false)
                                        setTwoFactorCompletionModalVisible(true)
                                        callLoadingLine(50, "Please enter the code from your Authenticator App.")
                                        setQRCodeValue("")
                                    }}
                                    sx={{
                                        marginTop: 3
                                    }}
                                >
                                    I've scanned the QR code
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setTwoFactorQRVisible(false)
                                        callLoadingLine(-1, "")
                                        setQRCodeValue("")
                                        snackCaller("2FA setup cancelled")
                                    }}
                                    sx={{
                                        marginTop: 3
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Modal>

                        <Modal
                            open={twoFactorCompletionModalVisible}
                            onClose={() => {
                                setTwoFactorCompletionModalVisible(false)
                                callLoadingLine(-1, "")
                                setTwoFactorCompletionCode("")
                                snackCaller("2FA setup cancelled")
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 440,
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                    borderRadius: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography variant={"body1"}>Please enter the code from your Authenticator App.</Typography>

                                <TextField
                                    sx={{
                                        mt: 2
                                    }}
                                    type={"number"}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value.length <= 6) {
                                            setTwoFactorCompletionCode(value);
                                        }
                                    }}
                                    // set max length to 6
                                    inputProps={{ style: { textAlign: "center" } }}
                                    label="Code"
                                    value={twoFactorCompletionCode}
                                />

                                <Button
                                    variant="contained"
                                    disabled={twoFactorCompletionCode.length !== 6}
                                    onClick={() => {
                                        callLoadingLine(75, "Verifying...")

                                        setTimeout(() => {
                                            Util.postRequest("/api/user/enable2FA", { code: twoFactorCompletionCode }).then((res) => {
                                                callLoadingLine(-1, "")
                                                setTwoFactorCompletionModalVisible(false)
                                                setTwoFactorCompletionCode("")
                                                snackCaller("2FA setup completed")
                                            }).catch((err) => {
                                                callLoadingLine(50, "Please enter the code from your Authenticator App.")
                                                setTwoFactorCompletionCode("")
                                                snackCaller("Invalid code")
                                            })
                                        }, 250);
                                    }}
                                    sx={{
                                        marginTop: 3
                                    }}
                                >
                                    Verify
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setTwoFactorCompletionModalVisible(false)
                                        callLoadingLine(-1, "")
                                        setTwoFactorCompletionCode("")
                                        snackCaller("2FA setup cancelled")
                                    }}
                                    sx={{
                                        marginTop: 3
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Modal>

                        <Button
                            disabled
                            variant="contained"
                            onClick={() => {
                                Util.postRequest("/api/user/setup2FA").then((res) => {
                                    callLoadingLine(0, "Please scan the QR code on your Authenticator App.")
                                    setQRCodeValue(res.secret)
                                    setTwoFactorQRVisible(true)
                                })
                            }}
                            sx={{
                                margin: 2
                            }}
                        >
                            Begin 2FA Setup
                        </Button>
                    </Box>
                </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
                <Box
                    sx={{
                        textAlign: "left",
                        maxWidth: "xs",
                        width: "100%",
                    }}
                >
                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Auto Update Client
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Set your client to auto update when a new version is available. Affects every client where you are logged in.
                            <br />
                            <strong>Mandatory updates will still be installed automatically.</strong>
                        </Typography>

                        <FormControl style={{ marginLeft: 20 }} >
                            <FormControlLabel control={<Switch checked={user.autoUpdateClient} onChange={(e) => {
                                Util.postRequest("/api/user/changeAutoUpdateClient", { autoUpdateClient: e.target.checked }).then((res) => {
                                    setUser({ ...user, autoUpdateClient: res.autoUpdateClient });
                                    localStorage.setItem("user", JSON.stringify({ ...user, autoUpdateClient: res.autoUpdateClient }));
                                })
                            }} />} label={user.autoUpdateClient ? "Auto Update" : "Auto Updates Turned Off"} />
                        </FormControl>

                        {Util.isElectron() && <Box>
                            <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                                Client Update Channel
                            </Typography>

                            <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                                Set your client update channel. You can choose between the channels at any time. Stable channel is recommended for most users.
                                <br />
                                <i>A restart is required for update channel preferences to be active. This setting is not synced.</i>
                            </Typography>

                            <Select
                                value={currentChannel || "Stable"}
                                style={{ marginLeft: 20, marginTop: 15, marginBottom: 15 }}
                                onChange={(e) => {
                                    window.ipcRenderer.invoke("setClientVersionChannel", {
                                        channel: e.target.value
                                    }).then(() => {
                                        setCurrentChannel(e.target.value)
                                    })
                                }}
                            >
                                {
                                    versionChannels.map((channel) => {
                                        return (
                                            <MenuItem value={channel}>{channel}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>}
                    </Box>

                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Auto Update Games
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Set your games to auto update when a new version is available. Affects every client where you are logged in.
                            <br />
                        </Typography>


                        <FormControl style={{ marginLeft: 20 }} >
                            <FormControlLabel control={<Switch checked={user.autoUpdateGames} onChange={(e) => {
                                Util.postRequest("/api/user/changeAutoUpdateGames", { autoUpdateGames: e.target.checked }).then((res) => {
                                    setUser({ ...user, autoUpdateGames: res.autoUpdateGames });
                                    localStorage.setItem("user", JSON.stringify({ ...user, autoUpdateGames: res.autoUpdateGames }));
                                })
                            }} />} label={user.autoUpdateGames ? "Auto Update" : "Auto Updates Turned Off"} />
                        </FormControl>
                    </Box>

                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Custom Titlebar
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            If you don't like our custom titlebar, you can disable it. This will make the client look like a normal window.
                            <br />
                            <strong>Requires a restart.</strong>
                        </Typography>


                        <FormControl style={{ marginLeft: 20 }} >
                            <FormControlLabel control={<Switch checked={user.customTitlebar} onChange={(e) => {
                                if (isElectron()) {
                                    window.ipcRenderer.invoke("toggleCustomTitlebar")
                                    setUser({ ...user, customTitlebar: e.target.checked });
                                } else {
                                    snackCaller("This feature is only available in the client.")
                                }
                            }} />} label={user.customTitlebar ? "Custom Titlebar" : "System Titlebar"} />
                        </FormControl>
                    </Box>

                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Color Theme
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Set your color theme. This will affect the site and every client where you are logged in.
                            <br />
                            It gets boring to see the same colors all the time, right?
                        </Typography>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "20px",
                                marginTop: 2,
                                gap: 2.5

                            }}
                        >
                            {[
                                "#9f3aeb",
                                "#f69565",
                                "#ff2400",
                                "#eb4034",
                                "#a6e3f9",
                                "#68b2ff",
                                "#0097E9",
                                "#fcba03",
                                "#32a852",
                                "#de37a6",
                                "#32cd32"
                            ].map((color) => {
                                return (
                                    <ButtonBase
                                        sx={{
                                            width: 50,
                                            height: 50,
                                            borderRadius: 50,
                                            backgroundColor: color
                                        }}
                                        onClick={() => {
                                            Util.postRequest("/api/user/changeColorTheme", { colorTheme: color }).then((res) => {
                                                setUser({ ...user, colorTheme: res.colorTheme });
                                                localStorage.setItem("user", JSON.stringify({ ...user, colorTheme: res.colorTheme }));
                                                setTheme(createTheme({
                                                    palette: {
                                                        mode: "dark",
                                                        primary: {
                                                            main: res.colorTheme
                                                        },
                                                        secondary: {
                                                            main: '#a6e3f9',
                                                        },
                                                        background: {
                                                            default: '#121212',
                                                            paper: '#212121',
                                                        },
                                                    },
                                                }))
                                            })
                                        }}
                                    >
                                        {(user.colorTheme === color || (color === "#f69565" && !user.colorTheme)) && <Check
                                            sx={{
                                                color: theme.palette.getContrastText(color),
                                            }}
                                        />}
                                    </ButtonBase>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={4}>
                <Box
                    sx={{
                        textAlign: "left",
                        maxWidth: "xs",
                        width: "100%",
                    }}
                >
                    <Box>
                        <Typography variant="h5" component="h1" style={{ marginLeft: 20, marginTop: 20 }}>
                            Unwanted Tags
                        </Typography>

                        <Typography variant="body1" component="p" style={{ marginLeft: 20 }}>
                            Hide games with tags you dislike from your Explore page. You can still search for these games directly.<br />
                            Applies on every browser and client where you are logged in.
                        </Typography>

                        <Autocomplete
                            sx={{
                                mt: 3,
                                ml: "20px",
                                width: 450
                            }}
                            multiple
                            id="name"
                            options={tagOptions || []} // This should be an array of possible tags
                            freeSolo
                            value={dislikedTags || []}
                            onChange={(e, v) => {
                                if (!v) return
                                let val

                                val = v.map((tag) => {
                                    return tag.name || tag
                                })

                                val = val.filter(v => v)

                                setDislikedTags(val)
                            }}
                            getOptionLabel={(option) => option.name || option}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                return filtered;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Unwanted Tags"
                                    type="text"
                                    style={{
                                    }}
                                    fullWidth
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            setTagOptions([])
                                            return
                                        }
                                        Util.postRequest("/api/tags/autocomplete", { query: e.target.value }).then((res) => {
                                            console.log(res)
                                            setTagOptions(res)
                                        })
                                    }}
                                />
                            )}
                        />

                        <Button
                            variant="contained"
                            sx={{
                                ml: "20px",
                                mt: 3
                            }}
                            onClick={() => {
                                Util.postRequest("/api/user/changeDislikedTags", {
                                    dislikedTags
                                }).then((res) => {
                                    Util.postRequest("/api/auth")
                                        .then((data) => {
                                            if (data?.user) {
                                                localStorage.setItem("user", JSON.stringify(data.user))
                                                setUser({ ...data.user })
                                            }
                                        })
                                })
                            }}
                        >
                            Save Unwanted Tags
                        </Button>
                    </Box>
                </Box>
            </CustomTabPanel>
        </Box>
    );
};

export default UserSettings;