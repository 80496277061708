import { Typography, Link } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const AuthSubbar = (props) => {
    const navigate = props.navigate;
    return (
        <div>
            <Typography sx={{ flexDirection: "row" }} variant="p" component="p" color="primary" gutterBottom>
                By continuing, you agree to our <Typography variant="a" sx={{ textDecoration: "underline", cursor: "pointer" }} color="secondary" onClick={() => { navigate("/terms") }} href="">Terms of Service</Typography> and <Typography variant="a" color="secondary" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { navigate("/privacy") }} href="">Privacy Policy</Typography>.
            </Typography>
        </div>
    );
};

export default AuthSubbar;