import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    return (
        <div style={{ marginTop: 20 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Please use the Vorecade app to upload and manage games.
            </Typography>

            <Button
                variant="contained"
                onClick={() => navigate('/download')}
            >
                Download Vorecade
            </Button>
        </div>
    );
};

export default Home;