import React from 'react';

const LinearGradient = ({ x1 = 0, y1 = 0, color1 = 'white', x2 = 1, y2 = 1, color2 = 'black' }) => {
    const angle = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;

    const backgroundStyle = {
        background: `linear-gradient(${angle}deg, ${color1}, ${color2})`,
        width: "100%", 
        height: "100%"
    };

    return <div style={backgroundStyle}></div>;
};

export default LinearGradient;